/* animation: blinking */

@keyframes animationBlinking {
    0% { opacity: 0.5; }
    100% { opacity: 1; }
}



/* screen around */
