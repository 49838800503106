body { line-height: $line_height_base; font-size: $font_size_base; }
body, select, input, textarea, button, select { color: $color; font-family: $normalized_font_family; }

a[href^="mailto:"] { font-feature-settings: "salt"; }
a { color: $link_color; text-decoration: none; transition: $transition_default;
    .no-touch &:hover { color: $link_color_hover; }
	&:link { -webkit-tap-highlight-color: $color_brand; }
}

::-moz-selection { background: $selection_bg; color: $selection_text; text-shadow: none; }
::selection { background: $selection_bg; color: $selection_text; text-shadow: none; }

h1 { }
h2 { }
h3 { }
h4 { }