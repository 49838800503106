/* VARIABLES ------------------------------------------------------------------------- */
/* VENDORS ------------------------------------------------------------------------- */
/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: http://meyerweb.com
	HTML5 Doctor				:: http://html5doctor.com
	and the HTML5 Boilerplate	:: http://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary { margin: 0; padding: 0; border: 0; font-size: 100%; font-weight: inherit; vertical-align: baseline; background: transparent; }

article, aside, figure, footer, header, nav, section, details, summary { display: block; }

/* Handle box-sizing while better addressing child elements: http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/* Responsive images and other embedded objects Note: keeping IMG here will cause problems if you're using foreground images as sprites. If this default setting for images is causing issues, you might want to replace it with a .responsive class instead. */
img, object, embed { max-width: 100%; }

/* force a vertical scrollbar to prevent a jumpy page */
html { overflow-y: scroll; }

/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul { list-style: none; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }

a { margin: 0; padding: 0; font-size: 100%; vertical-align: baseline; background: transparent; }

del { text-decoration: line-through; }

abbr[title], dfn[title] { border-bottom: 1px dotted #000; cursor: help; }

/* tables still need cellspacing="0" in the markup */
table { border-collapse: collapse; border-spacing: 0; }

th { font-weight: bold; vertical-align: bottom; }

td { font-weight: normal; vertical-align: top; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0; }

input, select { vertical-align: middle; }

pre { white-space: pre; /* CSS2 */ white-space: pre-wrap; /* CSS 2.1 */ white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */ word-wrap: break-word; /* IE */ }

input[type="radio"] { vertical-align: text-bottom; }

input[type="checkbox"] { vertical-align: bottom; }

.ie7 input[type="checkbox"] { vertical-align: baseline; }

.ie6 input { vertical-align: text-bottom; }

select, input, textarea { font: 99% sans-serif; }

table { font-size: inherit; font: 100%; }

small { font-size: 85%; }

strong { font-weight: bold; }

td, td img { vertical-align: top; }

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup { font-size: 75%; line-height: 0; position: relative; }

sup { top: -0.5em; }

sub { bottom: -0.25em; }

/* standardize any monospaced elements */
pre, code, kbd, samp { font-family: monospace, sans-serif; }

/* hand cursor on clickable elements */
.clickable, label, input[type=button], input[type=submit], input[type=file], button { cursor: pointer; }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea { margin: 0; }

/* make buttons play nice in IE */
button, input[type=button] { width: auto; overflow: visible; }

/* scale images in IE7 more attractively */
.ie7 img { -ms-interpolation-mode: bicubic; }

/* prevent BG image flicker upon hover (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:before, .clearfix:after { content: "\0020"; display: block; height: 0; overflow: hidden; }

.clearfix:after { clear: both; }

.clearfix { zoom: 1; }

/* COMMON STUFF ------------------------------------------------------------------------- */
body { -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: 100%; overflow-x: hidden; background: #2c2c2c; }

*, *:before, *:after { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; }

input, button, textarea, select { -webkit-appearance: none; -webkit-font-smoothing: antialiased; }

* { outline: 0 !important; }

img { height: auto; image-rendering: -moz-crisp-edges; image-rendering: -o-crisp-edges; image-rendering: -webkit-optimize-contrast; image-rendering: crisp-edges; }

.clearfix { zoom: 1; }
.clearfix:before, .clearfix:after { content: "\0020"; display: block; height: 0; overflow: hidden; }
.clearfix:after { clear: both; }

a, button { touch-action: none; }

button, input { -webkit-appearance: none; border-radius: 0; }

* { -webkit-tap-highlight-color: transparent !important; }

.fb-xfbml-parse-ignore { visibility: hidden; }

.alignleft { float: left; margin: 0 3rem 3rem 0; }

@font-face { font-family: 'ITCFranklinGothicStd'; src: url("../fonts/ITCFranklinGothicStd-DmXCp.otf") format("truetype"); font-weight: normal; font-style: normal; }
body { line-height: 1.58621; font-size: 2.9rem; }

body, select, input, textarea, button, select { color: #2c2c2c; font-family: "ITCFranklinGothicStd", sans-serif; }

a[href^="mailto:"] { font-feature-settings: "salt"; }

a { color: #fff; text-decoration: none; transition: all 300ms ease; }
.no-touch a:hover { color: #ffc52e; }
a:link { -webkit-tap-highlight-color: #ffc52e; }

::-moz-selection { background: #ffc52e; color: #fff; text-shadow: none; }

::selection { background: #ffc52e; color: #fff; text-shadow: none; }

/* animation: blinking */
@keyframes animationBlinking { 0% { opacity: 0.5; }
  100% { opacity: 1; } }
/* screen around */
/* ELEMENTS ------------------------------------------------------------------------- */
.button { display: block; width: 22.73%; height: 13.79%; padding: 2.8% 0 1.88%; color: #fff; font-size: 24px; line-height: 24px; text-align: center; border-radius: 5px; text-transform: uppercase; white-space: nowrap; }
@media screen and (max-width: 767px) and (orientation: landscape) { .button { font-size: 18px; padding: 0; line-height: 36px; height: 30px; width: 105px; } }
@media screen and (max-width: 1023px) { .button { font-size: 18px; padding: 0; line-height: 36px; height: 30px; width: 105px; } }
.button--red { background: #d82a2f; }
.no-touch .button--red:hover { background: #ffc52e; color: #fff; }
.button--border { border: 2px solid #fff; padding-top: 2.6%; }
.no-touch .button--border:hover { background: #fff; color: #363636; }
@media screen and (max-width: 767px) and (orientation: landscape) { .button--border { border-width: 1px; line-height: 34px; } }
@media screen and (max-width: 1023px) { .button--border { border-width: 1px; line-height: 34px; padding-top: 0%; } }
.button--spotify { background: #2c2c2c url(../img/icon-spotify.png) no-repeat center left 22px; padding-left: 70px; padding-right: 30px; width: auto; }
@media screen and (max-width: 1023px) { .button--spotify { background-position: center left 10px; background-size: 18px; padding-left: 40px; padding-right: 15px; } }

.spin-again { display: inline-block; position: absolute; top: 10px; left: 30px; color: #fff; font-size: 24px; text-transform: uppercase; z-index: 3; }
.spin-again:before { content: ''; display: block; width: 0; height: 0; border-style: solid; border-width: 4px 6px 4px 0; border-color: transparent #fff transparent transparent; position: absolute; left: -16px; top: 10px; transition: all 250ms ease-out; }
@media screen and (max-width: 1023px) { .spin-again:before { top: 7px; } }
.spin-again:hover { color: #ffc52e; }
.spin-again:hover:before { border-color: transparent #ffc52e transparent transparent; }
@media screen and (max-width: 1023px) { .spin-again { font-size: 18px; } }

.bg-animation { position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 1; overflow: hidden; }
.bg-animation svg { display: block; width: auto; height: 300%; position: absolute; top: -60%; left: 50%; transform: translateX(-50%); }
@media screen and (min-width: 768px) { .bg-animation svg .bg-color-1 { animation: alt-color-1 2000ms ease-out; }
  .bg-animation svg .bg-color-2 { animation: alt-color-2 2000ms ease-out; }
  .bg-animation svg .bg-color-3 { animation: alt-color-3 2000ms ease-out; } }
@keyframes alt-color-1 { 0% { fill: #363636; }
  33% { fill: #2C2C2C; }
  100% { fill: #363636; } }
@keyframes alt-color-2 { 0% { fill: #313131; }
  33% { fill: #363636; }
  100% { fill: #2C2C2C; } }
@keyframes alt-color-3 { 0% { fill: #2C2C2C; }
  33% { fill: #313131; }
  100% { fill: #363636; } }
@media screen and (min-width: 768px) { .bg-animation[data-animation="default"] { background-color: #363636; }
  .bg-animation[data-animation="default"] svg .bg-color-1 { animation: alt-color-1--default 1000ms infinite linear; }
  .bg-animation[data-animation="default"] svg .bg-color-2 { animation: alt-color-2--default 1000ms infinite linear; }
  .bg-animation[data-animation="default"] svg .bg-color-3 { animation: alt-color-3--default 1000ms infinite linear; } }
@keyframes alt-color-1--default { 0% { fill: #363636; }
  33% { fill: #2C2C2C; }
  66% { fill: #313131; }
  100% { fill: #363636; } }
@keyframes alt-color-2--default { 0% { fill: #313131; }
  33% { fill: #363636; }
  66% { fill: #2C2C2C; }
  100% { fill: #313131; } }
@keyframes alt-color-3--default { 0% { fill: #2C2C2C; }
  33% { fill: #313131; }
  66% { fill: #363636; }
  100% { fill: #2C2C2C; } }
@media screen and (min-width: 768px) { .bg-animation[data-animation="black"] { background-color: #2C2C2C; }
  .bg-animation[data-animation="black"] svg .bg-color-1 { animation: alt-color-1--black 1000ms infinite linear; }
  .bg-animation[data-animation="black"] svg .bg-color-2 { animation: alt-color-2--black 1000ms infinite linear; }
  .bg-animation[data-animation="black"] svg .bg-color-3 { animation: alt-color-3--black 1000ms infinite linear; } }
@keyframes alt-color-1--black { 0% { fill: #2C2C2C; }
  33% { fill: #111; }
  66% { fill: #1e1e1e; }
  100% { fill: #2C2C2C; } }
@keyframes alt-color-2--black { 0% { fill: #1e1e1e; }
  33% { fill: #2C2C2C; }
  66% { fill: #111; }
  100% { fill: #1e1e1e; } }
@keyframes alt-color-3--black { 0% { fill: #111; }
  33% { fill: #1e1e1e; }
  66% { fill: #2C2C2C; }
  100% { fill: #111; } }
@media screen and (min-width: 768px) { .bg-animation[data-animation="red"] { background-color: #da3439; }
  .bg-animation[data-animation="red"] svg .bg-color-1 { animation: alt-color-1--red 1000ms infinite linear; }
  .bg-animation[data-animation="red"] svg .bg-color-2 { animation: alt-color-2--red 1000ms infinite linear; }
  .bg-animation[data-animation="red"] svg .bg-color-3 { animation: alt-color-3--red 1000ms infinite linear; } }
@keyframes alt-color-1--red { 0% { fill: #da3439; }
  33% { fill: #c52429; }
  66% { fill: #cf2c31; }
  100% { fill: #da3439; } }
@keyframes alt-color-2--red { 0% { fill: #cf2c31; }
  33% { fill: #da3439; }
  66% { fill: #c52429; }
  100% { fill: #cf2c31; } }
@keyframes alt-color-3--red { 0% { fill: #c52429; }
  33% { fill: #cf2c31; }
  66% { fill: #da3439; }
  100% { fill: #c52429; } }
@media screen and (min-width: 768px) { .bg-animation[data-animation="pink"] { background-color: #f698a9; }
  .bg-animation[data-animation="pink"] svg .bg-color-1 { animation: alt-color-1--pink 1000ms infinite linear; }
  .bg-animation[data-animation="pink"] svg .bg-color-2 { animation: alt-color-2--pink 1000ms infinite linear; }
  .bg-animation[data-animation="pink"] svg .bg-color-3 { animation: alt-color-3--pink 1000ms infinite linear; } }
@keyframes alt-color-1--pink { 0% { fill: #f698a9; }
  33% { fill: #e98793; }
  66% { fill: #ef8f9e; }
  100% { fill: #f698a9; } }
@keyframes alt-color-2--pink { 0% { fill: #ef8f9e; }
  33% { fill: #f698a9; }
  66% { fill: #e98793; }
  100% { fill: #ef8f9e; } }
@keyframes alt-color-3--pink { 0% { fill: #e98793; }
  33% { fill: #ef8f9e; }
  66% { fill: #f698a9; }
  100% { fill: #e98793; } }
@media screen and (min-width: 768px) { .bg-animation[data-animation="green"] { background-color: #099c3f; }
  .bg-animation[data-animation="green"] svg .bg-color-1 { animation: alt-color-1--green 1000ms infinite linear; }
  .bg-animation[data-animation="green"] svg .bg-color-2 { animation: alt-color-2--green 1000ms infinite linear; }
  .bg-animation[data-animation="green"] svg .bg-color-3 { animation: alt-color-3--green 1000ms infinite linear; } }
@keyframes alt-color-1--green { 0% { fill: #099c3f; }
  33% { fill: #078937; }
  66% { fill: #08923b; }
  100% { fill: #099c3f; } }
@keyframes alt-color-2--green { 0% { fill: #08923b; }
  33% { fill: #099c3f; }
  66% { fill: #078937; }
  100% { fill: #08923b; } }
@keyframes alt-color-3--green { 0% { fill: #078937; }
  33% { fill: #08923b; }
  66% { fill: #099c3f; }
  100% { fill: #078937; } }
@media screen and (min-width: 768px) { .bg-animation[data-animation="purple"] { background-color: #6c549e; }
  .bg-animation[data-animation="purple"] svg .bg-color-1 { animation: alt-color-1--purple 1000ms infinite linear; }
  .bg-animation[data-animation="purple"] svg .bg-color-2 { animation: alt-color-2--purple 1000ms infinite linear; }
  .bg-animation[data-animation="purple"] svg .bg-color-3 { animation: alt-color-3--purple 1000ms infinite linear; } }
@keyframes alt-color-1--purple { 0% { fill: #6c549e; }
  33% { fill: #642681; }
  66% { fill: #683d8f; }
  100% { fill: #6c549e; } }
@keyframes alt-color-2--purple { 0% { fill: #683d8f; }
  33% { fill: #6c549e; }
  66% { fill: #642681; }
  100% { fill: #683d8f; } }
@keyframes alt-color-3--purple { 0% { fill: #642681; }
  33% { fill: #683d8f; }
  66% { fill: #6c549e; }
  100% { fill: #642681; } }
@media screen and (min-width: 768px) { .bg-animation[data-animation="lightblue"] { background-color: #d2eef8; }
  .bg-animation[data-animation="lightblue"] svg .bg-color-1 { animation: alt-color-1--lightblue 1000ms infinite linear; }
  .bg-animation[data-animation="lightblue"] svg .bg-color-2 { animation: alt-color-2--lightblue 1000ms infinite linear; }
  .bg-animation[data-animation="lightblue"] svg .bg-color-3 { animation: alt-color-3--lightblue 1000ms infinite linear; } }
@keyframes alt-color-1--lightblue { 0% { fill: #d2eef8; }
  33% { fill: #bbdeea; }
  66% { fill: #c6e6f1; }
  100% { fill: #d2eef8; } }
@keyframes alt-color-2--lightblue { 0% { fill: #c6e6f1; }
  33% { fill: #d2eef8; }
  66% { fill: #bbdeea; }
  100% { fill: #c6e6f1; } }
@keyframes alt-color-3--lightblue { 0% { fill: #bbdeea; }
  33% { fill: #c6e6f1; }
  66% { fill: #d2eef8; }
  100% { fill: #bbdeea; } }
@media screen and (min-width: 768px) { .bg-animation[data-animation="blue"] { background-color: #0e82ca; }
  .bg-animation[data-animation="blue"] svg .bg-color-1 { animation: alt-color-1--blue 1000ms infinite linear; }
  .bg-animation[data-animation="blue"] svg .bg-color-2 { animation: alt-color-2--blue 1000ms infinite linear; }
  .bg-animation[data-animation="blue"] svg .bg-color-3 { animation: alt-color-3--blue 1000ms infinite linear; } }
@keyframes alt-color-1--blue { 0% { fill: #0e82ca; }
  33% { fill: #0273ba; }
  66% { fill: #087ac2; }
  100% { fill: #0e82ca; } }
@keyframes alt-color-2--blue { 0% { fill: #087ac2; }
  33% { fill: #0e82ca; }
  66% { fill: #0273ba; }
  100% { fill: #087ac2; } }
@keyframes alt-color-3--blue { 0% { fill: #0273ba; }
  33% { fill: #087ac2; }
  66% { fill: #0e82ca; }
  100% { fill: #0273ba; } }
.slot-machine { max-width: 1185px; height: 100vh; position: relative; z-index: 10; margin: 0 auto; }
.slot-machine .machine { position: absolute; bottom: 0px; width: 100%; }
@media screen and (max-width: 767px) and (orientation: landscape) { .slot-machine .machine { left: 10px; right: 10px; bottom: 10px; width: auto; } }
.slot-machine .machine svg { display: block; padding-top: 5.47%; max-height: 100%; width: auto; }
.slot-machine .machine svg.machine-mobile { display: none; padding: 0; }
@media screen and (max-width: 767px) and (orientation: landscape) { .slot-machine .machine svg.machine-desktop { display: none; }
  .slot-machine .machine svg.machine-mobile { display: block; } }
@media screen and (max-width: 767px) and (orientation: portrait) { .slot-machine .machine svg.machine-desktop { display: none; }
  .slot-machine .machine svg.machine-mobile { display: none; } }
.slot-machine .machine svg #Button-left-copy-2 { cursor: pointer; }
.slot-machine .machine svg #Lights circle, .slot-machine .machine svg #LightsMobile ellipse { opacity: 0.5; }
.slot-machine .machine svg #Lights[data-animation="animationBlinking"] circle, .slot-machine .machine svg #LightsMobile[data-animation="animationBlinking"] ellipse { animation: animationBlinking 320ms steps(2, start) infinite; }
.slot-machine .machine svg #mobile-button-active { pointer-events: none; visibility: hidden; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(1) { animation: snake-1 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(1) { animation: snake-1 2.5s infinite linear; }
@keyframes snake-1 { 0% { opacity: 0.5; }
  0.75926% { opacity: 0.5; }
  1.75926% { opacity: 1; }
  8.75926% { opacity: 1; }
  9.75926% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(2) { animation: snake-2 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(2) { animation: snake-2 2.5s infinite linear; }
@keyframes snake-2 { 0% { opacity: 0.5; }
  1.51852% { opacity: 0.5; }
  2.51852% { opacity: 1; }
  9.51852% { opacity: 1; }
  10.51852% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(3) { animation: snake-3 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(3) { animation: snake-3 2.5s infinite linear; }
@keyframes snake-3 { 0% { opacity: 0.5; }
  2.27778% { opacity: 0.5; }
  3.27778% { opacity: 1; }
  10.27778% { opacity: 1; }
  11.27778% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(4) { animation: snake-4 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(4) { animation: snake-4 2.5s infinite linear; }
@keyframes snake-4 { 0% { opacity: 0.5; }
  3.03704% { opacity: 0.5; }
  4.03704% { opacity: 1; }
  11.03704% { opacity: 1; }
  12.03704% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(5) { animation: snake-5 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(5) { animation: snake-5 2.5s infinite linear; }
@keyframes snake-5 { 0% { opacity: 0.5; }
  3.7963% { opacity: 0.5; }
  4.7963% { opacity: 1; }
  11.7963% { opacity: 1; }
  12.7963% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(6) { animation: snake-6 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(6) { animation: snake-6 2.5s infinite linear; }
@keyframes snake-6 { 0% { opacity: 0.5; }
  4.55556% { opacity: 0.5; }
  5.55556% { opacity: 1; }
  12.55556% { opacity: 1; }
  13.55556% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(7) { animation: snake-7 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(7) { animation: snake-7 2.5s infinite linear; }
@keyframes snake-7 { 0% { opacity: 0.5; }
  5.31481% { opacity: 0.5; }
  6.31481% { opacity: 1; }
  13.31481% { opacity: 1; }
  14.31481% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(8) { animation: snake-8 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(8) { animation: snake-8 2.5s infinite linear; }
@keyframes snake-8 { 0% { opacity: 0.5; }
  6.07407% { opacity: 0.5; }
  7.07407% { opacity: 1; }
  14.07407% { opacity: 1; }
  15.07407% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(9) { animation: snake-9 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(9) { animation: snake-9 2.5s infinite linear; }
@keyframes snake-9 { 0% { opacity: 0.5; }
  6.83333% { opacity: 0.5; }
  7.83333% { opacity: 1; }
  14.83333% { opacity: 1; }
  15.83333% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(10) { animation: snake-10 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(10) { animation: snake-10 2.5s infinite linear; }
@keyframes snake-10 { 0% { opacity: 0.5; }
  7.59259% { opacity: 0.5; }
  8.59259% { opacity: 1; }
  15.59259% { opacity: 1; }
  16.59259% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(11) { animation: snake-11 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(11) { animation: snake-11 2.5s infinite linear; }
@keyframes snake-11 { 0% { opacity: 0.5; }
  8.35185% { opacity: 0.5; }
  9.35185% { opacity: 1; }
  16.35185% { opacity: 1; }
  17.35185% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(12) { animation: snake-12 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(12) { animation: snake-12 2.5s infinite linear; }
@keyframes snake-12 { 0% { opacity: 0.5; }
  9.11111% { opacity: 0.5; }
  10.11111% { opacity: 1; }
  17.11111% { opacity: 1; }
  18.11111% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(13) { animation: snake-13 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(13) { animation: snake-13 2.5s infinite linear; }
@keyframes snake-13 { 0% { opacity: 0.5; }
  9.87037% { opacity: 0.5; }
  10.87037% { opacity: 1; }
  17.87037% { opacity: 1; }
  18.87037% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(14) { animation: snake-14 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(14) { animation: snake-14 2.5s infinite linear; }
@keyframes snake-14 { 0% { opacity: 0.5; }
  10.62963% { opacity: 0.5; }
  11.62963% { opacity: 1; }
  18.62963% { opacity: 1; }
  19.62963% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(15) { animation: snake-15 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(15) { animation: snake-15 2.5s infinite linear; }
@keyframes snake-15 { 0% { opacity: 0.5; }
  11.38889% { opacity: 0.5; }
  12.38889% { opacity: 1; }
  19.38889% { opacity: 1; }
  20.38889% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(16) { animation: snake-16 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(16) { animation: snake-16 2.5s infinite linear; }
@keyframes snake-16 { 0% { opacity: 0.5; }
  12.14815% { opacity: 0.5; }
  13.14815% { opacity: 1; }
  20.14815% { opacity: 1; }
  21.14815% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(17) { animation: snake-17 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(17) { animation: snake-17 2.5s infinite linear; }
@keyframes snake-17 { 0% { opacity: 0.5; }
  12.90741% { opacity: 0.5; }
  13.90741% { opacity: 1; }
  20.90741% { opacity: 1; }
  21.90741% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(18) { animation: snake-18 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(18) { animation: snake-18 2.5s infinite linear; }
@keyframes snake-18 { 0% { opacity: 0.5; }
  13.66667% { opacity: 0.5; }
  14.66667% { opacity: 1; }
  21.66667% { opacity: 1; }
  22.66667% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(19) { animation: snake-19 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(19) { animation: snake-19 2.5s infinite linear; }
@keyframes snake-19 { 0% { opacity: 0.5; }
  14.42593% { opacity: 0.5; }
  15.42593% { opacity: 1; }
  22.42593% { opacity: 1; }
  23.42593% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(20) { animation: snake-20 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(20) { animation: snake-20 2.5s infinite linear; }
@keyframes snake-20 { 0% { opacity: 0.5; }
  15.18519% { opacity: 0.5; }
  16.18519% { opacity: 1; }
  23.18519% { opacity: 1; }
  24.18519% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(21) { animation: snake-21 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(21) { animation: snake-21 2.5s infinite linear; }
@keyframes snake-21 { 0% { opacity: 0.5; }
  15.94444% { opacity: 0.5; }
  16.94444% { opacity: 1; }
  23.94444% { opacity: 1; }
  24.94444% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(22) { animation: snake-22 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(22) { animation: snake-22 2.5s infinite linear; }
@keyframes snake-22 { 0% { opacity: 0.5; }
  16.7037% { opacity: 0.5; }
  17.7037% { opacity: 1; }
  24.7037% { opacity: 1; }
  25.7037% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(23) { animation: snake-23 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(23) { animation: snake-23 2.5s infinite linear; }
@keyframes snake-23 { 0% { opacity: 0.5; }
  17.46296% { opacity: 0.5; }
  18.46296% { opacity: 1; }
  25.46296% { opacity: 1; }
  26.46296% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(24) { animation: snake-24 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(24) { animation: snake-24 2.5s infinite linear; }
@keyframes snake-24 { 0% { opacity: 0.5; }
  18.22222% { opacity: 0.5; }
  19.22222% { opacity: 1; }
  26.22222% { opacity: 1; }
  27.22222% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(25) { animation: snake-25 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(25) { animation: snake-25 2.5s infinite linear; }
@keyframes snake-25 { 0% { opacity: 0.5; }
  18.98148% { opacity: 0.5; }
  19.98148% { opacity: 1; }
  26.98148% { opacity: 1; }
  27.98148% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(26) { animation: snake-26 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(26) { animation: snake-26 2.5s infinite linear; }
@keyframes snake-26 { 0% { opacity: 0.5; }
  19.74074% { opacity: 0.5; }
  20.74074% { opacity: 1; }
  27.74074% { opacity: 1; }
  28.74074% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(27) { animation: snake-27 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(27) { animation: snake-27 2.5s infinite linear; }
@keyframes snake-27 { 0% { opacity: 0.5; }
  20.5% { opacity: 0.5; }
  21.5% { opacity: 1; }
  28.5% { opacity: 1; }
  29.5% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(28) { animation: snake-28 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(28) { animation: snake-28 2.5s infinite linear; }
@keyframes snake-28 { 0% { opacity: 0.5; }
  21.25926% { opacity: 0.5; }
  22.25926% { opacity: 1; }
  29.25926% { opacity: 1; }
  30.25926% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(29) { animation: snake-29 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(29) { animation: snake-29 2.5s infinite linear; }
@keyframes snake-29 { 0% { opacity: 0.5; }
  22.01852% { opacity: 0.5; }
  23.01852% { opacity: 1; }
  30.01852% { opacity: 1; }
  31.01852% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(30) { animation: snake-30 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(30) { animation: snake-30 2.5s infinite linear; }
@keyframes snake-30 { 0% { opacity: 0.5; }
  22.77778% { opacity: 0.5; }
  23.77778% { opacity: 1; }
  30.77778% { opacity: 1; }
  31.77778% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(31) { animation: snake-31 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(31) { animation: snake-31 2.5s infinite linear; }
@keyframes snake-31 { 0% { opacity: 0.5; }
  23.53704% { opacity: 0.5; }
  24.53704% { opacity: 1; }
  31.53704% { opacity: 1; }
  32.53704% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(32) { animation: snake-32 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(32) { animation: snake-32 2.5s infinite linear; }
@keyframes snake-32 { 0% { opacity: 0.5; }
  24.2963% { opacity: 0.5; }
  25.2963% { opacity: 1; }
  32.2963% { opacity: 1; }
  33.2963% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(33) { animation: snake-33 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(33) { animation: snake-33 2.5s infinite linear; }
@keyframes snake-33 { 0% { opacity: 0.5; }
  25.05556% { opacity: 0.5; }
  26.05556% { opacity: 1; }
  33.05556% { opacity: 1; }
  34.05556% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(34) { animation: snake-34 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(34) { animation: snake-34 2.5s infinite linear; }
@keyframes snake-34 { 0% { opacity: 0.5; }
  25.81481% { opacity: 0.5; }
  26.81481% { opacity: 1; }
  33.81481% { opacity: 1; }
  34.81481% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(35) { animation: snake-35 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(35) { animation: snake-35 2.5s infinite linear; }
@keyframes snake-35 { 0% { opacity: 0.5; }
  26.57407% { opacity: 0.5; }
  27.57407% { opacity: 1; }
  34.57407% { opacity: 1; }
  35.57407% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(36) { animation: snake-36 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(36) { animation: snake-36 2.5s infinite linear; }
@keyframes snake-36 { 0% { opacity: 0.5; }
  27.33333% { opacity: 0.5; }
  28.33333% { opacity: 1; }
  35.33333% { opacity: 1; }
  36.33333% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(37) { animation: snake-37 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(37) { animation: snake-37 2.5s infinite linear; }
@keyframes snake-37 { 0% { opacity: 0.5; }
  28.09259% { opacity: 0.5; }
  29.09259% { opacity: 1; }
  36.09259% { opacity: 1; }
  37.09259% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(38) { animation: snake-38 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(38) { animation: snake-38 2.5s infinite linear; }
@keyframes snake-38 { 0% { opacity: 0.5; }
  28.85185% { opacity: 0.5; }
  29.85185% { opacity: 1; }
  36.85185% { opacity: 1; }
  37.85185% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(39) { animation: snake-39 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(39) { animation: snake-39 2.5s infinite linear; }
@keyframes snake-39 { 0% { opacity: 0.5; }
  29.61111% { opacity: 0.5; }
  30.61111% { opacity: 1; }
  37.61111% { opacity: 1; }
  38.61111% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(40) { animation: snake-40 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(40) { animation: snake-40 2.5s infinite linear; }
@keyframes snake-40 { 0% { opacity: 0.5; }
  30.37037% { opacity: 0.5; }
  31.37037% { opacity: 1; }
  38.37037% { opacity: 1; }
  39.37037% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(41) { animation: snake-41 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(41) { animation: snake-41 2.5s infinite linear; }
@keyframes snake-41 { 0% { opacity: 0.5; }
  31.12963% { opacity: 0.5; }
  32.12963% { opacity: 1; }
  39.12963% { opacity: 1; }
  40.12963% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(42) { animation: snake-42 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(42) { animation: snake-42 2.5s infinite linear; }
@keyframes snake-42 { 0% { opacity: 0.5; }
  31.88889% { opacity: 0.5; }
  32.88889% { opacity: 1; }
  39.88889% { opacity: 1; }
  40.88889% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(43) { animation: snake-43 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(43) { animation: snake-43 2.5s infinite linear; }
@keyframes snake-43 { 0% { opacity: 0.5; }
  32.64815% { opacity: 0.5; }
  33.64815% { opacity: 1; }
  40.64815% { opacity: 1; }
  41.64815% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(44) { animation: snake-44 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(44) { animation: snake-44 2.5s infinite linear; }
@keyframes snake-44 { 0% { opacity: 0.5; }
  33.40741% { opacity: 0.5; }
  34.40741% { opacity: 1; }
  41.40741% { opacity: 1; }
  42.40741% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(45) { animation: snake-45 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(45) { animation: snake-45 2.5s infinite linear; }
@keyframes snake-45 { 0% { opacity: 0.5; }
  34.16667% { opacity: 0.5; }
  35.16667% { opacity: 1; }
  42.16667% { opacity: 1; }
  43.16667% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(46) { animation: snake-46 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(46) { animation: snake-46 2.5s infinite linear; }
@keyframes snake-46 { 0% { opacity: 0.5; }
  34.92593% { opacity: 0.5; }
  35.92593% { opacity: 1; }
  42.92593% { opacity: 1; }
  43.92593% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(47) { animation: snake-47 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(47) { animation: snake-47 2.5s infinite linear; }
@keyframes snake-47 { 0% { opacity: 0.5; }
  35.68519% { opacity: 0.5; }
  36.68519% { opacity: 1; }
  43.68519% { opacity: 1; }
  44.68519% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(48) { animation: snake-48 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(48) { animation: snake-48 2.5s infinite linear; }
@keyframes snake-48 { 0% { opacity: 0.5; }
  36.44444% { opacity: 0.5; }
  37.44444% { opacity: 1; }
  44.44444% { opacity: 1; }
  45.44444% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(49) { animation: snake-49 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(49) { animation: snake-49 2.5s infinite linear; }
@keyframes snake-49 { 0% { opacity: 0.5; }
  37.2037% { opacity: 0.5; }
  38.2037% { opacity: 1; }
  45.2037% { opacity: 1; }
  46.2037% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(50) { animation: snake-50 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(50) { animation: snake-50 2.5s infinite linear; }
@keyframes snake-50 { 0% { opacity: 0.5; }
  37.96296% { opacity: 0.5; }
  38.96296% { opacity: 1; }
  45.96296% { opacity: 1; }
  46.96296% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(51) { animation: snake-51 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(51) { animation: snake-51 2.5s infinite linear; }
@keyframes snake-51 { 0% { opacity: 0.5; }
  38.72222% { opacity: 0.5; }
  39.72222% { opacity: 1; }
  46.72222% { opacity: 1; }
  47.72222% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(52) { animation: snake-52 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(52) { animation: snake-52 2.5s infinite linear; }
@keyframes snake-52 { 0% { opacity: 0.5; }
  39.48148% { opacity: 0.5; }
  40.48148% { opacity: 1; }
  47.48148% { opacity: 1; }
  48.48148% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(53) { animation: snake-53 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(53) { animation: snake-53 2.5s infinite linear; }
@keyframes snake-53 { 0% { opacity: 0.5; }
  40.24074% { opacity: 0.5; }
  41.24074% { opacity: 1; }
  48.24074% { opacity: 1; }
  49.24074% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] circle:nth-child(54) { animation: snake-54 2.5s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationSnake"] ellipse:nth-child(54) { animation: snake-54 2.5s infinite linear; }
@keyframes snake-54 { 0% { opacity: 0.5; }
  41% { opacity: 0.5; }
  42% { opacity: 1; }
  49% { opacity: 1; }
  50% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(1) { animation: snake-1 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(1) { animation: snake-1 2.5s infinite linear; }
@keyframes snake-1 { 0% { opacity: 0.5; }
  0.73214% { opacity: 0.5; }
  1.73214% { opacity: 1; }
  8.73214% { opacity: 1; }
  9.73214% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(2) { animation: snake-2 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(2) { animation: snake-2 2.5s infinite linear; }
@keyframes snake-2 { 0% { opacity: 0.5; }
  1.46429% { opacity: 0.5; }
  2.46429% { opacity: 1; }
  9.46429% { opacity: 1; }
  10.46429% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(3) { animation: snake-3 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(3) { animation: snake-3 2.5s infinite linear; }
@keyframes snake-3 { 0% { opacity: 0.5; }
  2.19643% { opacity: 0.5; }
  3.19643% { opacity: 1; }
  10.19643% { opacity: 1; }
  11.19643% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(4) { animation: snake-4 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(4) { animation: snake-4 2.5s infinite linear; }
@keyframes snake-4 { 0% { opacity: 0.5; }
  2.92857% { opacity: 0.5; }
  3.92857% { opacity: 1; }
  10.92857% { opacity: 1; }
  11.92857% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(5) { animation: snake-5 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(5) { animation: snake-5 2.5s infinite linear; }
@keyframes snake-5 { 0% { opacity: 0.5; }
  3.66071% { opacity: 0.5; }
  4.66071% { opacity: 1; }
  11.66071% { opacity: 1; }
  12.66071% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(6) { animation: snake-6 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(6) { animation: snake-6 2.5s infinite linear; }
@keyframes snake-6 { 0% { opacity: 0.5; }
  4.39286% { opacity: 0.5; }
  5.39286% { opacity: 1; }
  12.39286% { opacity: 1; }
  13.39286% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(7) { animation: snake-7 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(7) { animation: snake-7 2.5s infinite linear; }
@keyframes snake-7 { 0% { opacity: 0.5; }
  5.125% { opacity: 0.5; }
  6.125% { opacity: 1; }
  13.125% { opacity: 1; }
  14.125% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(8) { animation: snake-8 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(8) { animation: snake-8 2.5s infinite linear; }
@keyframes snake-8 { 0% { opacity: 0.5; }
  5.85714% { opacity: 0.5; }
  6.85714% { opacity: 1; }
  13.85714% { opacity: 1; }
  14.85714% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(9) { animation: snake-9 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(9) { animation: snake-9 2.5s infinite linear; }
@keyframes snake-9 { 0% { opacity: 0.5; }
  6.58929% { opacity: 0.5; }
  7.58929% { opacity: 1; }
  14.58929% { opacity: 1; }
  15.58929% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(10) { animation: snake-10 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(10) { animation: snake-10 2.5s infinite linear; }
@keyframes snake-10 { 0% { opacity: 0.5; }
  7.32143% { opacity: 0.5; }
  8.32143% { opacity: 1; }
  15.32143% { opacity: 1; }
  16.32143% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(11) { animation: snake-11 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(11) { animation: snake-11 2.5s infinite linear; }
@keyframes snake-11 { 0% { opacity: 0.5; }
  8.05357% { opacity: 0.5; }
  9.05357% { opacity: 1; }
  16.05357% { opacity: 1; }
  17.05357% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(12) { animation: snake-12 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(12) { animation: snake-12 2.5s infinite linear; }
@keyframes snake-12 { 0% { opacity: 0.5; }
  8.78571% { opacity: 0.5; }
  9.78571% { opacity: 1; }
  16.78571% { opacity: 1; }
  17.78571% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(13) { animation: snake-13 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(13) { animation: snake-13 2.5s infinite linear; }
@keyframes snake-13 { 0% { opacity: 0.5; }
  9.51786% { opacity: 0.5; }
  10.51786% { opacity: 1; }
  17.51786% { opacity: 1; }
  18.51786% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(14) { animation: snake-14 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(14) { animation: snake-14 2.5s infinite linear; }
@keyframes snake-14 { 0% { opacity: 0.5; }
  10.25% { opacity: 0.5; }
  11.25% { opacity: 1; }
  18.25% { opacity: 1; }
  19.25% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(15) { animation: snake-15 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(15) { animation: snake-15 2.5s infinite linear; }
@keyframes snake-15 { 0% { opacity: 0.5; }
  10.98214% { opacity: 0.5; }
  11.98214% { opacity: 1; }
  18.98214% { opacity: 1; }
  19.98214% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(16) { animation: snake-16 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(16) { animation: snake-16 2.5s infinite linear; }
@keyframes snake-16 { 0% { opacity: 0.5; }
  11.71429% { opacity: 0.5; }
  12.71429% { opacity: 1; }
  19.71429% { opacity: 1; }
  20.71429% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(17) { animation: snake-17 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(17) { animation: snake-17 2.5s infinite linear; }
@keyframes snake-17 { 0% { opacity: 0.5; }
  12.44643% { opacity: 0.5; }
  13.44643% { opacity: 1; }
  20.44643% { opacity: 1; }
  21.44643% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(18) { animation: snake-18 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(18) { animation: snake-18 2.5s infinite linear; }
@keyframes snake-18 { 0% { opacity: 0.5; }
  13.17857% { opacity: 0.5; }
  14.17857% { opacity: 1; }
  21.17857% { opacity: 1; }
  22.17857% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(19) { animation: snake-19 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(19) { animation: snake-19 2.5s infinite linear; }
@keyframes snake-19 { 0% { opacity: 0.5; }
  13.91071% { opacity: 0.5; }
  14.91071% { opacity: 1; }
  21.91071% { opacity: 1; }
  22.91071% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(20) { animation: snake-20 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(20) { animation: snake-20 2.5s infinite linear; }
@keyframes snake-20 { 0% { opacity: 0.5; }
  14.64286% { opacity: 0.5; }
  15.64286% { opacity: 1; }
  22.64286% { opacity: 1; }
  23.64286% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(21) { animation: snake-21 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(21) { animation: snake-21 2.5s infinite linear; }
@keyframes snake-21 { 0% { opacity: 0.5; }
  15.375% { opacity: 0.5; }
  16.375% { opacity: 1; }
  23.375% { opacity: 1; }
  24.375% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(22) { animation: snake-22 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(22) { animation: snake-22 2.5s infinite linear; }
@keyframes snake-22 { 0% { opacity: 0.5; }
  16.10714% { opacity: 0.5; }
  17.10714% { opacity: 1; }
  24.10714% { opacity: 1; }
  25.10714% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(23) { animation: snake-23 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(23) { animation: snake-23 2.5s infinite linear; }
@keyframes snake-23 { 0% { opacity: 0.5; }
  16.83929% { opacity: 0.5; }
  17.83929% { opacity: 1; }
  24.83929% { opacity: 1; }
  25.83929% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(24) { animation: snake-24 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(24) { animation: snake-24 2.5s infinite linear; }
@keyframes snake-24 { 0% { opacity: 0.5; }
  17.57143% { opacity: 0.5; }
  18.57143% { opacity: 1; }
  25.57143% { opacity: 1; }
  26.57143% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(25) { animation: snake-25 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(25) { animation: snake-25 2.5s infinite linear; }
@keyframes snake-25 { 0% { opacity: 0.5; }
  18.30357% { opacity: 0.5; }
  19.30357% { opacity: 1; }
  26.30357% { opacity: 1; }
  27.30357% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(26) { animation: snake-26 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(26) { animation: snake-26 2.5s infinite linear; }
@keyframes snake-26 { 0% { opacity: 0.5; }
  19.03571% { opacity: 0.5; }
  20.03571% { opacity: 1; }
  27.03571% { opacity: 1; }
  28.03571% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(27) { animation: snake-27 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(27) { animation: snake-27 2.5s infinite linear; }
@keyframes snake-27 { 0% { opacity: 0.5; }
  19.76786% { opacity: 0.5; }
  20.76786% { opacity: 1; }
  27.76786% { opacity: 1; }
  28.76786% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(28) { animation: snake-28 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(28) { animation: snake-28 2.5s infinite linear; }
@keyframes snake-28 { 0% { opacity: 0.5; }
  20.5% { opacity: 0.5; }
  21.5% { opacity: 1; }
  28.5% { opacity: 1; }
  29.5% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(29) { animation: snake-29 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(29) { animation: snake-29 2.5s infinite linear; }
@keyframes snake-29 { 0% { opacity: 0.5; }
  21.23214% { opacity: 0.5; }
  22.23214% { opacity: 1; }
  29.23214% { opacity: 1; }
  30.23214% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(30) { animation: snake-30 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(30) { animation: snake-30 2.5s infinite linear; }
@keyframes snake-30 { 0% { opacity: 0.5; }
  21.96429% { opacity: 0.5; }
  22.96429% { opacity: 1; }
  29.96429% { opacity: 1; }
  30.96429% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(31) { animation: snake-31 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(31) { animation: snake-31 2.5s infinite linear; }
@keyframes snake-31 { 0% { opacity: 0.5; }
  22.69643% { opacity: 0.5; }
  23.69643% { opacity: 1; }
  30.69643% { opacity: 1; }
  31.69643% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(32) { animation: snake-32 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(32) { animation: snake-32 2.5s infinite linear; }
@keyframes snake-32 { 0% { opacity: 0.5; }
  23.42857% { opacity: 0.5; }
  24.42857% { opacity: 1; }
  31.42857% { opacity: 1; }
  32.42857% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(33) { animation: snake-33 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(33) { animation: snake-33 2.5s infinite linear; }
@keyframes snake-33 { 0% { opacity: 0.5; }
  24.16071% { opacity: 0.5; }
  25.16071% { opacity: 1; }
  32.16071% { opacity: 1; }
  33.16071% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(34) { animation: snake-34 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(34) { animation: snake-34 2.5s infinite linear; }
@keyframes snake-34 { 0% { opacity: 0.5; }
  24.89286% { opacity: 0.5; }
  25.89286% { opacity: 1; }
  32.89286% { opacity: 1; }
  33.89286% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(35) { animation: snake-35 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(35) { animation: snake-35 2.5s infinite linear; }
@keyframes snake-35 { 0% { opacity: 0.5; }
  25.625% { opacity: 0.5; }
  26.625% { opacity: 1; }
  33.625% { opacity: 1; }
  34.625% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(36) { animation: snake-36 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(36) { animation: snake-36 2.5s infinite linear; }
@keyframes snake-36 { 0% { opacity: 0.5; }
  26.35714% { opacity: 0.5; }
  27.35714% { opacity: 1; }
  34.35714% { opacity: 1; }
  35.35714% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(37) { animation: snake-37 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(37) { animation: snake-37 2.5s infinite linear; }
@keyframes snake-37 { 0% { opacity: 0.5; }
  27.08929% { opacity: 0.5; }
  28.08929% { opacity: 1; }
  35.08929% { opacity: 1; }
  36.08929% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(38) { animation: snake-38 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(38) { animation: snake-38 2.5s infinite linear; }
@keyframes snake-38 { 0% { opacity: 0.5; }
  27.82143% { opacity: 0.5; }
  28.82143% { opacity: 1; }
  35.82143% { opacity: 1; }
  36.82143% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(39) { animation: snake-39 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(39) { animation: snake-39 2.5s infinite linear; }
@keyframes snake-39 { 0% { opacity: 0.5; }
  28.55357% { opacity: 0.5; }
  29.55357% { opacity: 1; }
  36.55357% { opacity: 1; }
  37.55357% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(40) { animation: snake-40 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(40) { animation: snake-40 2.5s infinite linear; }
@keyframes snake-40 { 0% { opacity: 0.5; }
  29.28571% { opacity: 0.5; }
  30.28571% { opacity: 1; }
  37.28571% { opacity: 1; }
  38.28571% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(41) { animation: snake-41 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(41) { animation: snake-41 2.5s infinite linear; }
@keyframes snake-41 { 0% { opacity: 0.5; }
  30.01786% { opacity: 0.5; }
  31.01786% { opacity: 1; }
  38.01786% { opacity: 1; }
  39.01786% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(42) { animation: snake-42 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(42) { animation: snake-42 2.5s infinite linear; }
@keyframes snake-42 { 0% { opacity: 0.5; }
  30.75% { opacity: 0.5; }
  31.75% { opacity: 1; }
  38.75% { opacity: 1; }
  39.75% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(43) { animation: snake-43 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(43) { animation: snake-43 2.5s infinite linear; }
@keyframes snake-43 { 0% { opacity: 0.5; }
  31.48214% { opacity: 0.5; }
  32.48214% { opacity: 1; }
  39.48214% { opacity: 1; }
  40.48214% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(44) { animation: snake-44 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(44) { animation: snake-44 2.5s infinite linear; }
@keyframes snake-44 { 0% { opacity: 0.5; }
  32.21429% { opacity: 0.5; }
  33.21429% { opacity: 1; }
  40.21429% { opacity: 1; }
  41.21429% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(45) { animation: snake-45 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(45) { animation: snake-45 2.5s infinite linear; }
@keyframes snake-45 { 0% { opacity: 0.5; }
  32.94643% { opacity: 0.5; }
  33.94643% { opacity: 1; }
  40.94643% { opacity: 1; }
  41.94643% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(46) { animation: snake-46 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(46) { animation: snake-46 2.5s infinite linear; }
@keyframes snake-46 { 0% { opacity: 0.5; }
  33.67857% { opacity: 0.5; }
  34.67857% { opacity: 1; }
  41.67857% { opacity: 1; }
  42.67857% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(47) { animation: snake-47 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(47) { animation: snake-47 2.5s infinite linear; }
@keyframes snake-47 { 0% { opacity: 0.5; }
  34.41071% { opacity: 0.5; }
  35.41071% { opacity: 1; }
  42.41071% { opacity: 1; }
  43.41071% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(48) { animation: snake-48 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(48) { animation: snake-48 2.5s infinite linear; }
@keyframes snake-48 { 0% { opacity: 0.5; }
  35.14286% { opacity: 0.5; }
  36.14286% { opacity: 1; }
  43.14286% { opacity: 1; }
  44.14286% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(49) { animation: snake-49 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(49) { animation: snake-49 2.5s infinite linear; }
@keyframes snake-49 { 0% { opacity: 0.5; }
  35.875% { opacity: 0.5; }
  36.875% { opacity: 1; }
  43.875% { opacity: 1; }
  44.875% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(50) { animation: snake-50 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(50) { animation: snake-50 2.5s infinite linear; }
@keyframes snake-50 { 0% { opacity: 0.5; }
  36.60714% { opacity: 0.5; }
  37.60714% { opacity: 1; }
  44.60714% { opacity: 1; }
  45.60714% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(51) { animation: snake-51 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(51) { animation: snake-51 2.5s infinite linear; }
@keyframes snake-51 { 0% { opacity: 0.5; }
  37.33929% { opacity: 0.5; }
  38.33929% { opacity: 1; }
  45.33929% { opacity: 1; }
  46.33929% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(52) { animation: snake-52 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(52) { animation: snake-52 2.5s infinite linear; }
@keyframes snake-52 { 0% { opacity: 0.5; }
  38.07143% { opacity: 0.5; }
  39.07143% { opacity: 1; }
  46.07143% { opacity: 1; }
  47.07143% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(53) { animation: snake-53 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(53) { animation: snake-53 2.5s infinite linear; }
@keyframes snake-53 { 0% { opacity: 0.5; }
  38.80357% { opacity: 0.5; }
  39.80357% { opacity: 1; }
  46.80357% { opacity: 1; }
  47.80357% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(54) { animation: snake-54 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(54) { animation: snake-54 2.5s infinite linear; }
@keyframes snake-54 { 0% { opacity: 0.5; }
  39.53571% { opacity: 0.5; }
  40.53571% { opacity: 1; }
  47.53571% { opacity: 1; }
  48.53571% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(55) { animation: snake-55 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(55) { animation: snake-55 2.5s infinite linear; }
@keyframes snake-55 { 0% { opacity: 0.5; }
  40.26786% { opacity: 0.5; }
  41.26786% { opacity: 1; }
  48.26786% { opacity: 1; }
  49.26786% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] circle:nth-child(56) { animation: snake-56 2.5s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationSnake"] ellipse:nth-child(56) { animation: snake-56 2.5s infinite linear; }
@keyframes snake-56 { 0% { opacity: 0.5; }
  41% { opacity: 0.5; }
  42% { opacity: 1; }
  49% { opacity: 1; }
  50% { opacity: 0.5; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg .st10 { fill: #f2f2f2; transition: fill 300ms ease; }
.slot-machine .machine svg .st13 { fill-opacity: 1; }
.slot-machine .machine svg #Button-left-copy-2 .st7 { fill-opacity: 1; }
.slot-machine .machine svg #Group-22-copy .st10 { animation-delay: 50ms; }
.slot-machine .machine svg #Group-22-copy-2 .st10 { animation-delay: 100ms; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(1) { animation: around-1 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(1) { animation: around-1 3s infinite linear; }
@keyframes around-1 { 0% { opacity: 0.5; }
  0.94444% { opacity: 0.5; }
  1.94444% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(2) { animation: around-2 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(2) { animation: around-2 3s infinite linear; }
@keyframes around-2 { 0% { opacity: 0.5; }
  1.88889% { opacity: 0.5; }
  2.88889% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(3) { animation: around-3 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(3) { animation: around-3 3s infinite linear; }
@keyframes around-3 { 0% { opacity: 0.5; }
  2.83333% { opacity: 0.5; }
  3.83333% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(4) { animation: around-4 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(4) { animation: around-4 3s infinite linear; }
@keyframes around-4 { 0% { opacity: 0.5; }
  3.77778% { opacity: 0.5; }
  4.77778% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(5) { animation: around-5 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(5) { animation: around-5 3s infinite linear; }
@keyframes around-5 { 0% { opacity: 0.5; }
  4.72222% { opacity: 0.5; }
  5.72222% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(6) { animation: around-6 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(6) { animation: around-6 3s infinite linear; }
@keyframes around-6 { 0% { opacity: 0.5; }
  5.66667% { opacity: 0.5; }
  6.66667% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(7) { animation: around-7 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(7) { animation: around-7 3s infinite linear; }
@keyframes around-7 { 0% { opacity: 0.5; }
  6.61111% { opacity: 0.5; }
  7.61111% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(8) { animation: around-8 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(8) { animation: around-8 3s infinite linear; }
@keyframes around-8 { 0% { opacity: 0.5; }
  7.55556% { opacity: 0.5; }
  8.55556% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(9) { animation: around-9 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(9) { animation: around-9 3s infinite linear; }
@keyframes around-9 { 0% { opacity: 0.5; }
  8.5% { opacity: 0.5; }
  9.5% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(10) { animation: around-10 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(10) { animation: around-10 3s infinite linear; }
@keyframes around-10 { 0% { opacity: 0.5; }
  9.44444% { opacity: 0.5; }
  10.44444% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(11) { animation: around-11 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(11) { animation: around-11 3s infinite linear; }
@keyframes around-11 { 0% { opacity: 0.5; }
  10.38889% { opacity: 0.5; }
  11.38889% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(12) { animation: around-12 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(12) { animation: around-12 3s infinite linear; }
@keyframes around-12 { 0% { opacity: 0.5; }
  11.33333% { opacity: 0.5; }
  12.33333% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(13) { animation: around-13 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(13) { animation: around-13 3s infinite linear; }
@keyframes around-13 { 0% { opacity: 0.5; }
  12.27778% { opacity: 0.5; }
  13.27778% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(14) { animation: around-14 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(14) { animation: around-14 3s infinite linear; }
@keyframes around-14 { 0% { opacity: 0.5; }
  13.22222% { opacity: 0.5; }
  14.22222% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(15) { animation: around-15 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(15) { animation: around-15 3s infinite linear; }
@keyframes around-15 { 0% { opacity: 0.5; }
  14.16667% { opacity: 0.5; }
  15.16667% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(16) { animation: around-16 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(16) { animation: around-16 3s infinite linear; }
@keyframes around-16 { 0% { opacity: 0.5; }
  15.11111% { opacity: 0.5; }
  16.11111% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(17) { animation: around-17 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(17) { animation: around-17 3s infinite linear; }
@keyframes around-17 { 0% { opacity: 0.5; }
  16.05556% { opacity: 0.5; }
  17.05556% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(18) { animation: around-18 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(18) { animation: around-18 3s infinite linear; }
@keyframes around-18 { 0% { opacity: 0.5; }
  17% { opacity: 0.5; }
  18% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(19) { animation: around-19 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(19) { animation: around-19 3s infinite linear; }
@keyframes around-19 { 0% { opacity: 0.5; }
  17.94444% { opacity: 0.5; }
  18.94444% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(20) { animation: around-20 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(20) { animation: around-20 3s infinite linear; }
@keyframes around-20 { 0% { opacity: 0.5; }
  18.88889% { opacity: 0.5; }
  19.88889% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(21) { animation: around-21 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(21) { animation: around-21 3s infinite linear; }
@keyframes around-21 { 0% { opacity: 0.5; }
  19.83333% { opacity: 0.5; }
  20.83333% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(22) { animation: around-22 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(22) { animation: around-22 3s infinite linear; }
@keyframes around-22 { 0% { opacity: 0.5; }
  20.77778% { opacity: 0.5; }
  21.77778% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(23) { animation: around-23 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(23) { animation: around-23 3s infinite linear; }
@keyframes around-23 { 0% { opacity: 0.5; }
  21.72222% { opacity: 0.5; }
  22.72222% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(24) { animation: around-24 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(24) { animation: around-24 3s infinite linear; }
@keyframes around-24 { 0% { opacity: 0.5; }
  22.66667% { opacity: 0.5; }
  23.66667% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(25) { animation: around-25 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(25) { animation: around-25 3s infinite linear; }
@keyframes around-25 { 0% { opacity: 0.5; }
  23.61111% { opacity: 0.5; }
  24.61111% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(26) { animation: around-26 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(26) { animation: around-26 3s infinite linear; }
@keyframes around-26 { 0% { opacity: 0.5; }
  24.55556% { opacity: 0.5; }
  25.55556% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(27) { animation: around-27 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(27) { animation: around-27 3s infinite linear; }
@keyframes around-27 { 0% { opacity: 0.5; }
  25.5% { opacity: 0.5; }
  26.5% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(28) { animation: around-28 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(28) { animation: around-28 3s infinite linear; }
@keyframes around-28 { 0% { opacity: 0.5; }
  26.44444% { opacity: 0.5; }
  27.44444% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(29) { animation: around-29 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(29) { animation: around-29 3s infinite linear; }
@keyframes around-29 { 0% { opacity: 0.5; }
  27.38889% { opacity: 0.5; }
  28.38889% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(30) { animation: around-30 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(30) { animation: around-30 3s infinite linear; }
@keyframes around-30 { 0% { opacity: 0.5; }
  28.33333% { opacity: 0.5; }
  29.33333% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(31) { animation: around-31 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(31) { animation: around-31 3s infinite linear; }
@keyframes around-31 { 0% { opacity: 0.5; }
  29.27778% { opacity: 0.5; }
  30.27778% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(32) { animation: around-32 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(32) { animation: around-32 3s infinite linear; }
@keyframes around-32 { 0% { opacity: 0.5; }
  30.22222% { opacity: 0.5; }
  31.22222% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(33) { animation: around-33 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(33) { animation: around-33 3s infinite linear; }
@keyframes around-33 { 0% { opacity: 0.5; }
  31.16667% { opacity: 0.5; }
  32.16667% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(34) { animation: around-34 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(34) { animation: around-34 3s infinite linear; }
@keyframes around-34 { 0% { opacity: 0.5; }
  32.11111% { opacity: 0.5; }
  33.11111% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(35) { animation: around-35 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(35) { animation: around-35 3s infinite linear; }
@keyframes around-35 { 0% { opacity: 0.5; }
  33.05556% { opacity: 0.5; }
  34.05556% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(36) { animation: around-36 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(36) { animation: around-36 3s infinite linear; }
@keyframes around-36 { 0% { opacity: 0.5; }
  34% { opacity: 0.5; }
  35% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(37) { animation: around-37 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(37) { animation: around-37 3s infinite linear; }
@keyframes around-37 { 0% { opacity: 0.5; }
  34.94444% { opacity: 0.5; }
  35.94444% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(38) { animation: around-38 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(38) { animation: around-38 3s infinite linear; }
@keyframes around-38 { 0% { opacity: 0.5; }
  35.88889% { opacity: 0.5; }
  36.88889% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(39) { animation: around-39 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(39) { animation: around-39 3s infinite linear; }
@keyframes around-39 { 0% { opacity: 0.5; }
  36.83333% { opacity: 0.5; }
  37.83333% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(40) { animation: around-40 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(40) { animation: around-40 3s infinite linear; }
@keyframes around-40 { 0% { opacity: 0.5; }
  37.77778% { opacity: 0.5; }
  38.77778% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(41) { animation: around-41 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(41) { animation: around-41 3s infinite linear; }
@keyframes around-41 { 0% { opacity: 0.5; }
  38.72222% { opacity: 0.5; }
  39.72222% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(42) { animation: around-42 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(42) { animation: around-42 3s infinite linear; }
@keyframes around-42 { 0% { opacity: 0.5; }
  39.66667% { opacity: 0.5; }
  40.66667% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(43) { animation: around-43 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(43) { animation: around-43 3s infinite linear; }
@keyframes around-43 { 0% { opacity: 0.5; }
  40.61111% { opacity: 0.5; }
  41.61111% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(44) { animation: around-44 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(44) { animation: around-44 3s infinite linear; }
@keyframes around-44 { 0% { opacity: 0.5; }
  41.55556% { opacity: 0.5; }
  42.55556% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(45) { animation: around-45 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(45) { animation: around-45 3s infinite linear; }
@keyframes around-45 { 0% { opacity: 0.5; }
  42.5% { opacity: 0.5; }
  43.5% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(46) { animation: around-46 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(46) { animation: around-46 3s infinite linear; }
@keyframes around-46 { 0% { opacity: 0.5; }
  43.44444% { opacity: 0.5; }
  44.44444% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(47) { animation: around-47 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(47) { animation: around-47 3s infinite linear; }
@keyframes around-47 { 0% { opacity: 0.5; }
  44.38889% { opacity: 0.5; }
  45.38889% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(48) { animation: around-48 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(48) { animation: around-48 3s infinite linear; }
@keyframes around-48 { 0% { opacity: 0.5; }
  45.33333% { opacity: 0.5; }
  46.33333% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(49) { animation: around-49 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(49) { animation: around-49 3s infinite linear; }
@keyframes around-49 { 0% { opacity: 0.5; }
  46.27778% { opacity: 0.5; }
  47.27778% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(50) { animation: around-50 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(50) { animation: around-50 3s infinite linear; }
@keyframes around-50 { 0% { opacity: 0.5; }
  47.22222% { opacity: 0.5; }
  48.22222% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(51) { animation: around-51 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(51) { animation: around-51 3s infinite linear; }
@keyframes around-51 { 0% { opacity: 0.5; }
  48.16667% { opacity: 0.5; }
  49.16667% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(52) { animation: around-52 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(52) { animation: around-52 3s infinite linear; }
@keyframes around-52 { 0% { opacity: 0.5; }
  49.11111% { opacity: 0.5; }
  50.11111% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(53) { animation: around-53 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(53) { animation: around-53 3s infinite linear; }
@keyframes around-53 { 0% { opacity: 0.5; }
  50.05556% { opacity: 0.5; }
  51.05556% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #Lights[data-animation="animationAround"] circle:nth-child(54) { animation: around-54 3s infinite linear; }
.slot-machine .machine svg #Lights[data-animation="animationAround"] ellipse:nth-child(54) { animation: around-54 3s infinite linear; }
@keyframes around-54 { 0% { opacity: 0.5; }
  51% { opacity: 0.5; }
  52% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(1) { animation: around-1 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(1) { animation: around-1 3s infinite linear; }
@keyframes around-1 { 0% { opacity: 0.5; }
  0.91071% { opacity: 0.5; }
  1.91071% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(2) { animation: around-2 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(2) { animation: around-2 3s infinite linear; }
@keyframes around-2 { 0% { opacity: 0.5; }
  1.82143% { opacity: 0.5; }
  2.82143% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(3) { animation: around-3 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(3) { animation: around-3 3s infinite linear; }
@keyframes around-3 { 0% { opacity: 0.5; }
  2.73214% { opacity: 0.5; }
  3.73214% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(4) { animation: around-4 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(4) { animation: around-4 3s infinite linear; }
@keyframes around-4 { 0% { opacity: 0.5; }
  3.64286% { opacity: 0.5; }
  4.64286% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(5) { animation: around-5 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(5) { animation: around-5 3s infinite linear; }
@keyframes around-5 { 0% { opacity: 0.5; }
  4.55357% { opacity: 0.5; }
  5.55357% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(6) { animation: around-6 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(6) { animation: around-6 3s infinite linear; }
@keyframes around-6 { 0% { opacity: 0.5; }
  5.46429% { opacity: 0.5; }
  6.46429% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(7) { animation: around-7 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(7) { animation: around-7 3s infinite linear; }
@keyframes around-7 { 0% { opacity: 0.5; }
  6.375% { opacity: 0.5; }
  7.375% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(8) { animation: around-8 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(8) { animation: around-8 3s infinite linear; }
@keyframes around-8 { 0% { opacity: 0.5; }
  7.28571% { opacity: 0.5; }
  8.28571% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(9) { animation: around-9 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(9) { animation: around-9 3s infinite linear; }
@keyframes around-9 { 0% { opacity: 0.5; }
  8.19643% { opacity: 0.5; }
  9.19643% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(10) { animation: around-10 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(10) { animation: around-10 3s infinite linear; }
@keyframes around-10 { 0% { opacity: 0.5; }
  9.10714% { opacity: 0.5; }
  10.10714% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(11) { animation: around-11 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(11) { animation: around-11 3s infinite linear; }
@keyframes around-11 { 0% { opacity: 0.5; }
  10.01786% { opacity: 0.5; }
  11.01786% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(12) { animation: around-12 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(12) { animation: around-12 3s infinite linear; }
@keyframes around-12 { 0% { opacity: 0.5; }
  10.92857% { opacity: 0.5; }
  11.92857% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(13) { animation: around-13 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(13) { animation: around-13 3s infinite linear; }
@keyframes around-13 { 0% { opacity: 0.5; }
  11.83929% { opacity: 0.5; }
  12.83929% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(14) { animation: around-14 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(14) { animation: around-14 3s infinite linear; }
@keyframes around-14 { 0% { opacity: 0.5; }
  12.75% { opacity: 0.5; }
  13.75% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(15) { animation: around-15 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(15) { animation: around-15 3s infinite linear; }
@keyframes around-15 { 0% { opacity: 0.5; }
  13.66071% { opacity: 0.5; }
  14.66071% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(16) { animation: around-16 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(16) { animation: around-16 3s infinite linear; }
@keyframes around-16 { 0% { opacity: 0.5; }
  14.57143% { opacity: 0.5; }
  15.57143% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(17) { animation: around-17 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(17) { animation: around-17 3s infinite linear; }
@keyframes around-17 { 0% { opacity: 0.5; }
  15.48214% { opacity: 0.5; }
  16.48214% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(18) { animation: around-18 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(18) { animation: around-18 3s infinite linear; }
@keyframes around-18 { 0% { opacity: 0.5; }
  16.39286% { opacity: 0.5; }
  17.39286% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(19) { animation: around-19 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(19) { animation: around-19 3s infinite linear; }
@keyframes around-19 { 0% { opacity: 0.5; }
  17.30357% { opacity: 0.5; }
  18.30357% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(20) { animation: around-20 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(20) { animation: around-20 3s infinite linear; }
@keyframes around-20 { 0% { opacity: 0.5; }
  18.21429% { opacity: 0.5; }
  19.21429% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(21) { animation: around-21 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(21) { animation: around-21 3s infinite linear; }
@keyframes around-21 { 0% { opacity: 0.5; }
  19.125% { opacity: 0.5; }
  20.125% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(22) { animation: around-22 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(22) { animation: around-22 3s infinite linear; }
@keyframes around-22 { 0% { opacity: 0.5; }
  20.03571% { opacity: 0.5; }
  21.03571% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(23) { animation: around-23 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(23) { animation: around-23 3s infinite linear; }
@keyframes around-23 { 0% { opacity: 0.5; }
  20.94643% { opacity: 0.5; }
  21.94643% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(24) { animation: around-24 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(24) { animation: around-24 3s infinite linear; }
@keyframes around-24 { 0% { opacity: 0.5; }
  21.85714% { opacity: 0.5; }
  22.85714% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(25) { animation: around-25 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(25) { animation: around-25 3s infinite linear; }
@keyframes around-25 { 0% { opacity: 0.5; }
  22.76786% { opacity: 0.5; }
  23.76786% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(26) { animation: around-26 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(26) { animation: around-26 3s infinite linear; }
@keyframes around-26 { 0% { opacity: 0.5; }
  23.67857% { opacity: 0.5; }
  24.67857% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(27) { animation: around-27 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(27) { animation: around-27 3s infinite linear; }
@keyframes around-27 { 0% { opacity: 0.5; }
  24.58929% { opacity: 0.5; }
  25.58929% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(28) { animation: around-28 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(28) { animation: around-28 3s infinite linear; }
@keyframes around-28 { 0% { opacity: 0.5; }
  25.5% { opacity: 0.5; }
  26.5% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(29) { animation: around-29 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(29) { animation: around-29 3s infinite linear; }
@keyframes around-29 { 0% { opacity: 0.5; }
  26.41071% { opacity: 0.5; }
  27.41071% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(30) { animation: around-30 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(30) { animation: around-30 3s infinite linear; }
@keyframes around-30 { 0% { opacity: 0.5; }
  27.32143% { opacity: 0.5; }
  28.32143% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(31) { animation: around-31 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(31) { animation: around-31 3s infinite linear; }
@keyframes around-31 { 0% { opacity: 0.5; }
  28.23214% { opacity: 0.5; }
  29.23214% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(32) { animation: around-32 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(32) { animation: around-32 3s infinite linear; }
@keyframes around-32 { 0% { opacity: 0.5; }
  29.14286% { opacity: 0.5; }
  30.14286% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(33) { animation: around-33 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(33) { animation: around-33 3s infinite linear; }
@keyframes around-33 { 0% { opacity: 0.5; }
  30.05357% { opacity: 0.5; }
  31.05357% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(34) { animation: around-34 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(34) { animation: around-34 3s infinite linear; }
@keyframes around-34 { 0% { opacity: 0.5; }
  30.96429% { opacity: 0.5; }
  31.96429% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(35) { animation: around-35 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(35) { animation: around-35 3s infinite linear; }
@keyframes around-35 { 0% { opacity: 0.5; }
  31.875% { opacity: 0.5; }
  32.875% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(36) { animation: around-36 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(36) { animation: around-36 3s infinite linear; }
@keyframes around-36 { 0% { opacity: 0.5; }
  32.78571% { opacity: 0.5; }
  33.78571% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(37) { animation: around-37 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(37) { animation: around-37 3s infinite linear; }
@keyframes around-37 { 0% { opacity: 0.5; }
  33.69643% { opacity: 0.5; }
  34.69643% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(38) { animation: around-38 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(38) { animation: around-38 3s infinite linear; }
@keyframes around-38 { 0% { opacity: 0.5; }
  34.60714% { opacity: 0.5; }
  35.60714% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(39) { animation: around-39 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(39) { animation: around-39 3s infinite linear; }
@keyframes around-39 { 0% { opacity: 0.5; }
  35.51786% { opacity: 0.5; }
  36.51786% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(40) { animation: around-40 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(40) { animation: around-40 3s infinite linear; }
@keyframes around-40 { 0% { opacity: 0.5; }
  36.42857% { opacity: 0.5; }
  37.42857% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(41) { animation: around-41 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(41) { animation: around-41 3s infinite linear; }
@keyframes around-41 { 0% { opacity: 0.5; }
  37.33929% { opacity: 0.5; }
  38.33929% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(42) { animation: around-42 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(42) { animation: around-42 3s infinite linear; }
@keyframes around-42 { 0% { opacity: 0.5; }
  38.25% { opacity: 0.5; }
  39.25% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(43) { animation: around-43 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(43) { animation: around-43 3s infinite linear; }
@keyframes around-43 { 0% { opacity: 0.5; }
  39.16071% { opacity: 0.5; }
  40.16071% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(44) { animation: around-44 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(44) { animation: around-44 3s infinite linear; }
@keyframes around-44 { 0% { opacity: 0.5; }
  40.07143% { opacity: 0.5; }
  41.07143% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(45) { animation: around-45 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(45) { animation: around-45 3s infinite linear; }
@keyframes around-45 { 0% { opacity: 0.5; }
  40.98214% { opacity: 0.5; }
  41.98214% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(46) { animation: around-46 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(46) { animation: around-46 3s infinite linear; }
@keyframes around-46 { 0% { opacity: 0.5; }
  41.89286% { opacity: 0.5; }
  42.89286% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(47) { animation: around-47 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(47) { animation: around-47 3s infinite linear; }
@keyframes around-47 { 0% { opacity: 0.5; }
  42.80357% { opacity: 0.5; }
  43.80357% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(48) { animation: around-48 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(48) { animation: around-48 3s infinite linear; }
@keyframes around-48 { 0% { opacity: 0.5; }
  43.71429% { opacity: 0.5; }
  44.71429% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(49) { animation: around-49 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(49) { animation: around-49 3s infinite linear; }
@keyframes around-49 { 0% { opacity: 0.5; }
  44.625% { opacity: 0.5; }
  45.625% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(50) { animation: around-50 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(50) { animation: around-50 3s infinite linear; }
@keyframes around-50 { 0% { opacity: 0.5; }
  45.53571% { opacity: 0.5; }
  46.53571% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(51) { animation: around-51 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(51) { animation: around-51 3s infinite linear; }
@keyframes around-51 { 0% { opacity: 0.5; }
  46.44643% { opacity: 0.5; }
  47.44643% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(52) { animation: around-52 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(52) { animation: around-52 3s infinite linear; }
@keyframes around-52 { 0% { opacity: 0.5; }
  47.35714% { opacity: 0.5; }
  48.35714% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(53) { animation: around-53 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(53) { animation: around-53 3s infinite linear; }
@keyframes around-53 { 0% { opacity: 0.5; }
  48.26786% { opacity: 0.5; }
  49.26786% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(54) { animation: around-54 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(54) { animation: around-54 3s infinite linear; }
@keyframes around-54 { 0% { opacity: 0.5; }
  49.17857% { opacity: 0.5; }
  50.17857% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(55) { animation: around-55 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(55) { animation: around-55 3s infinite linear; }
@keyframes around-55 { 0% { opacity: 0.5; }
  50.08929% { opacity: 0.5; }
  51.08929% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] circle:nth-child(56) { animation: around-56 3s infinite linear; }
.slot-machine .machine svg #LightsMobile[data-animation="animationAround"] ellipse:nth-child(56) { animation: around-56 3s infinite linear; }
@keyframes around-56 { 0% { opacity: 0.5; }
  51% { opacity: 0.5; }
  52% { opacity: 1; }
  61% { opacity: 1; }
  67% { opacity: 1; }
  70% { opacity: 0.5; }
  75% { opacity: 1; }
  80% { opacity: 0.5; }
  85% { opacity: 1; }
  90% { opacity: 0.5; }
  95% { opacity: 1; }
  100% { opacity: 0.5; } }
.slot-machine.is-horizontal .machine { width: auto; height: 100%; }
.slot-machine.is-horizontal .machine svg { width: auto; height: 100%; margin: 0 auto; max-width: 1185px; }
@media screen and (max-width: 767px) and (orientation: landscape) { .slot-machine.is-horizontal .machine { height: calc(100% - 20px); } }
.slot-machine .machine-screen { position: absolute; width: 64.98%; height: 43.303%; top: 28.5%; left: 50%; transform: translateX(-50%); border-radius: 5px; overflow: hidden; }
.slot-machine .machine-screen:before { content: ''; display: block; position: absolute; top: 0px; left: 5px; right: 0px; height: 5px; background-color: rgba(0, 0, 0, 0.1); z-index: 10; }
@media screen and (max-width: 767px) and (orientation: landscape) { .slot-machine .machine-screen:before { height: 3px; left: 3px; } }
.slot-machine .machine-screen:after { content: ''; display: block; position: absolute; top: 0px; bottom: 0px; left: 0px; width: 5px; background-color: rgba(0, 0, 0, 0.1); z-index: 10; }
@media screen and (max-width: 767px) and (orientation: landscape) { .slot-machine .machine-screen:after { width: 3px; } }
@media screen and (max-width: 767px) and (orientation: landscape) { .slot-machine .machine-screen { width: 86.96%; height: 60%; top: 9.8%; } }
.slot-machine .machine-screen .screen { width: 100%; height: 100%; background-repeat: no-repeat; background-position: center; display: none; }
.slot-machine .machine-screen .screen-start { display: block; background: #fff; }
.slot-machine .machine-screen .screen-start .claim { width: 85.06%; height: 59.31%; position: absolute; top: 8%; left: 50%; transform: translateX(-50%) scale(0); background: url(../img/screen-start-claim.png) no-repeat center; background-size: contain; }
.slot-machine .machine-screen .screen-start .emoji { width: 48.83%; height: 67.82%; position: absolute; bottom: -34%; left: 50%; transform: translateX(-50%) scale(0); background: url(../img/screen-start-emoji.png) no-repeat center; z-index: 1; margin-left: 2%; background-size: contain; }
.slot-machine .machine-screen .screen-start .button { position: absolute; bottom: 0px; left: 50%; transform: translateX(-50%); opacity: 0; z-index: 2; }
.slot-machine .machine-screen .screen-start.active .claim { transform: translateX(-50%) scale(1); transition: transform 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
.slot-machine .machine-screen .screen-start.active .emoji { transform: translateX(-50%) scale(1); bottom: 0px; transition: all 600ms 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
.slot-machine .machine-screen .screen-start.active .button { opacity: 1; bottom: 20px; transition: opacity 300ms 800ms ease-out, bottom 300ms 800ms ease-out, background 250ms ease-out; }
.slot-machine .machine-screen .screen-machine { background: #f2f2f2; }
.slot-machine .machine-screen .screen-gif { background: #333; background-size: cover; background-repeat: no-repeat; background-position: 50%; }
.slot-machine .machine-screen .screen-unlucky { background: #363636; }
.slot-machine .machine-screen .screen-unlucky .matches { position: absolute; top: 0px; left: 50%; transform: translateX(-50%); font-size: 24px; color: #fff; text-transform: uppercase; opacity: 1; }
@media screen and (max-width: 767px) and (orientation: landscape) { .slot-machine .machine-screen .screen-unlucky .matches { font-size: 18px; } }
@media screen and (max-width: 1023px) { .slot-machine .machine-screen .screen-unlucky .matches { font-size: 18px; } }
.slot-machine .machine-screen .screen-unlucky .claim { width: 81.69%; height: 31.95%; position: absolute; top: 34.48%; left: 50%; transform: translateX(-50%) scale(0); background: url(../img/screen-unlucky-claim.png) no-repeat center; background-size: contain; }
.slot-machine .machine-screen .screen-unlucky .emoji { width: 15.06%; height: 29.43%; position: absolute; top: 20.69%; left: 50%; transform: translateX(-50%) scale(0); background: url(../img/screen-unlucky-emoji.png) no-repeat center; z-index: 1; margin-left: 1.5%; background-size: contain; }
.slot-machine .machine-screen .screen-unlucky .button { position: absolute; bottom: 0px; left: 50%; transform: translateX(-50%); opacity: 0; z-index: 2; }
.slot-machine .machine-screen .screen-unlucky.active .matches { top: 3.45%; opacity: 1; transition: all 300ms ease-out; }
.slot-machine .machine-screen .screen-unlucky.active .claim { transform: translateX(-50%) scale(1); transition: transform 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
.slot-machine .machine-screen .screen-unlucky.active .emoji { transform: translateX(-50%) scale(1); transition: transform 600ms 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
.slot-machine .machine-screen .screen-unlucky.active .button { opacity: 1; bottom: 20px; transition: opacity 300ms 400ms ease-out, bottom 300ms 400ms ease-out, background 250ms ease-out, color 250ms ease-out; }
.slot-machine .machine-screen .screen-lucky .claim { position: absolute; left: 50%; transform: translateX(-50%) scale(0); background-repeat: no-repeat; background-position: center; background-size: contain; }
.slot-machine .machine-screen .screen-lucky .image { position: absolute; top: 0px; bottom: 0px; left: 0px; right: 0px; background-size: contain; background-repeat: no-repeat; background-position: center; }
.slot-machine .machine-screen .screen-lucky .button { position: absolute; bottom: 0px; left: 50%; transform: translateX(-50%); opacity: 0; z-index: 2; }
.slot-machine .machine-screen .screen-lucky[data-emoji="Thinking.jpg"] { background-color: #bcdfeb; background-image: linear-gradient(90deg, #d2eff8 0%, #badeea 100%); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Thinking.jpg"] .claim { width: 95.19%; height: 42.06%; top: 29%; background-image: url(../img/screen-lucky/thinking.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Thinking.jpg"] .image { background-image: url(../img/screen-lucky/img-thinking.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="HeartEyes.jpg"] { background-color: #e12026; background-image: linear-gradient(90deg, #e84040 0%, #e22228 77%, #db0410 100%); }
.slot-machine .machine-screen .screen-lucky[data-emoji="HeartEyes.jpg"] .claim { width: 93.51%; height: 52.64%; top: 18.39%; background-image: url(../img/screen-lucky/love.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="HeartEyes.jpg"] .image { background-image: url(../img/screen-lucky/img-love.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="CryCry.jpg"] { background-color: #0475bd; background-image: linear-gradient(90deg, #0e82ca 0%, #0273ba 100%); }
.slot-machine .machine-screen .screen-lucky[data-emoji="CryCry.jpg"] .claim { width: 94.68%; height: 49.43%; top: 26%; background-image: url(../img/screen-lucky/crying.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="CryCry.jpg"] .image { background-image: url(../img/screen-lucky/img-crying.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="BigSmile.jpg"] { background-color: #2d2d2d; background-image: linear-gradient(90deg, #363636 0%, #2c2c2c 100%); }
.slot-machine .machine-screen .screen-lucky[data-emoji="BigSmile.jpg"] .claim { width: 94.94%; height: 42.99%; top: 30%; background-image: url(../img/screen-lucky/upbeat.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="BigSmile.jpg"] .image { background-image: url(../img/screen-lucky/img-upbeat.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Candy.jpg"] { background-color: #652b84; background-image: linear-gradient(90deg, #6c569f 0%, #642580 100%); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Candy.jpg"] .claim { width: 94.81%; height: 38.62%; top: 31.03%; background-image: url(../img/screen-lucky/dance.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Candy.jpg"] .image { background-image: url(../img/screen-lucky/img-dance.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Captain.jpg"] { background-color: #652a83; background-image: linear-gradient(90deg, #f698a9 0%, #e98793 100%); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Captain.jpg"] .claim { width: 94.55%; height: 55.63%; top: 23%; background-image: url(../img/screen-lucky/swing.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Captain.jpg"] .image { background-image: url(../img/screen-lucky/img-swing.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Football5.jpg"] { background-color: #e22228; background-image: linear-gradient(90deg, #e84040 0%, #e22228 77%, #db0410 100%); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Football5.jpg"] .claim { width: 94.81%; height: 29.89%; top: 36.78%; background-image: url(../img/screen-lucky/workout.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Football5.jpg"] .image { background-image: url(../img/screen-lucky/img-workout.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Knebworth.jpg"] { background-color: #2d2d2d; background-image: linear-gradient(90deg, #363636 0%, #2c2c2c 100%); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Knebworth.jpg"] .claim { width: 78.83%; height: 57.47%; top: 20.7%; background-image: url(../img/screen-lucky/live.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Knebworth.jpg"] .image { background-image: url(../img/screen-lucky/img-live.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Sleeping.jpg"] { background-color: #2e2e2; background-image: linear-gradient(90deg, #099c3f 0%, #078937 100%); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Sleeping.jpg"] .claim { width: 94.81%; height: 65.06%; top: 17%; background-image: url(../img/screen-lucky/chill.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Sleeping.jpg"] .image { background-image: url(../img/screen-lucky/img-chill.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Defiant.jpg"] { background-color: #2e2e2e; background-image: linear-gradient(90deg, #363636 0%, #2c2c2c 100%); }
.slot-machine .machine-screen .screen-lucky[data-emoji="Defiant.jpg"] .claim { width: 49.35%; height: 47.13%; top: 19.54%; background-image: url(../img/screen-lucky/defiant.png); }
.slot-machine .machine-screen .screen-lucky[data-emoji="RocknRoll.jpg"] { background-color: #323232; background-image: linear-gradient(90deg, #363636 0%, #2c2c2c 100%); }
.slot-machine .machine-screen .screen-lucky[data-emoji="RocknRoll.jpg"] .claim { width: 41.81%; height: 59.55%; top: 14.77%; background-image: url(../img/screen-lucky/rocknroll.png); }
.slot-machine .machine-screen .screen-lucky.active .claim { transform: translateX(-50%) scale(1); transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
.slot-machine .machine-screen .screen-lucky.active .button { opacity: 1; bottom: 20px; transition: opacity 300ms 800ms ease-out, bottom 300ms 800ms ease-out, background 250ms ease-out, color 250ms ease-out; }
.slot-machine .machine-screen .screen-spotify { background: #fff; padding: 50px 20px 0 20px; }
.slot-machine .machine-screen .screen-spotify .spin-again { color: #2c2c2c; }
.slot-machine .machine-screen .screen-spotify .spin-again:before { border-color: transparent #2c2c2c transparent transparent; }
.slot-machine .machine-screen .screen-spotify .spin-again:hover { color: #ffc52e; }
.slot-machine .machine-screen .screen-spotify .spin-again:hover:before { border-color: transparent #ffc52e transparent transparent; }
.slot-machine .machine-screen .screen-spotify .iframe-container { position: absolute; top: 50px; bottom: 95px; left: 20px; right: 20px; overflow: hidden; }
.slot-machine .machine-screen .screen-spotify .iframe-container iframe { width: 100% !important; min-height: 280px !important; }
@media screen and (max-width: 767px) { .slot-machine .machine-screen .screen-spotify .iframe-container iframe { min-height: 80px !important; height: 80px !important; } }
@media screen and (max-width: 767px) { .slot-machine .machine-screen .screen-spotify .iframe-container { height: 80px; top: 40px; bottom: auto; } }
.slot-machine .machine-screen .screen-spotify .socials { position: absolute; bottom: 20px; left: 20px; right: 20px; }
@media screen and (max-width: 767px) { .slot-machine .machine-screen .screen-spotify .socials { bottom: 10px; }
  .vsmall .slot-machine .machine-screen .screen-spotify .socials .twitter { display: none; } }
@media screen and (max-width: 500px), screen and (max-width: 767px) and (max-height: 350px) { .slot-machine .machine-screen .screen-spotify .socials { display: none; } }
.slot-machine .machine-screen .screen-spotify .socials > * { height: 56px; line-height: 56px; }
.slot-machine .machine-screen .screen-spotify .socials .fb { float: left; margin-right: 10px; }
.slot-machine .machine-screen .screen-spotify .socials .twitter { float: left; }
.slot-machine .machine-screen .screen-spotify .socials .spotify { float: right; }
.slot-machine .machine-screen .slots { display: flex; margin: 0 -5px; }
@media screen and (max-width: 767px) and (orientation: landscape) { .slot-machine .machine-screen .slots { margin: 0 5px; } }
.slot-machine .machine-screen .slots .slot { width: 33.333%; overflow: hidden; position: relative; }
.slot-machine .machine-screen .slots .slot:before { content: ''; display: block; padding-top: 200%; }
.slot-machine .machine-screen .slots .slot-animation-wrapper { width: 100%; position: absolute; top: -40%; left: 0; will-change: transform; }
.slot-machine .machine-screen .slots .slot-item { padding: 5px; }
.slot-machine .machine-screen .slots .slot-item .slot-item-inner { overflow: hidden; background: #fff; position: relative; }
.slot-machine .machine-screen .slots .slot-item .slot-item-inner:after { content: ''; display: block; position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px; background: url(../img/emoji-shadow.png) no-repeat top center; background-size: contain; }
.slot-machine .machine-screen .slots .slot-item .slot-item-inner:before { display: block; content: ''; padding-top: 110%; }
@media screen and (max-width: 767px) and (orientation: landscape) { .slot-machine .machine-screen .slots .slot-item .slot-item-inner:before { padding-top: 100%; } }
.slot-machine .machine-screen .slots .slot-item img { display: block; height: auto; width: 100%; max-width: none; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
.slot-machine.ready-to-spin .st10 { animation: button-blink 300ms linear infinite alternate; }
.slot-machine.ready-to-spin .machine svg .st13 { fill-opacity: 0.15; }
.slot-machine.ready-to-spin .machine svg #Button-left-copy-2 .st7 { fill-opacity: 0.25; }
.slot-machine.ready-to-spin #mobile-button rect[fill="#d52a2e"] { animation: button-blink 300ms linear infinite alternate; }
.slot-machine.ready-to-spin #mobile-button:active + #mobile-button-active { visibility: visible; }
.slot-machine.ready-to-spin #mobile-button-active.touch-hover { visibility: visible; }
@keyframes button-blink { 0% { fill: #d7202f; }
  100% { fill: #f00; } }
.slot-machine:not(.ready-to-spin) #mobile-button rect[fill="#d52a2e"] { fill: #f2f2f2; }
.slot-machine:not(.ready-to-spin) #mobile-button path[fill="#631316"] { fill: #777; }
.slot-machine:not(.ready-to-spin) #mobile-button path[fill="#bc2026"] { fill: #dadada; }
.slot-machine:not(.ready-to-spin) #mobile-button path[fill="#f04a4e"] { fill: #fff; }

/* LAYOUT ------------------------------------------------------------------------- */
html { min-height: 100%; position: relative; overflow-x: hidden; }

html, body { overflow: hidden; }

.sme_cookie .sme_cookie_container { font-size: 18px; }
@media screen and (max-width: 767px) { .sme_cookie .sme_cookie_container { font-size: 14px; } }

.wrapper { width: 100%; max-width: 1185px; margin: 0 auto; }

.overall { position: fixed; top: 0; left: 0; right: 0; background: #2c2c2c; }

.official-website { position: absolute; top: 15px; right: 20px; color: #fff; font-size: 19px; z-index: 10; }
@media screen and (max-width: 767px) and (orientation: landscape) { .official-website { display: none; } }
@media screen and (max-width: 767px) and (orientation: portrait) { .official-website { top: 0px; left: 0px; right: 0px; position: fixed; background: #000; height: 40px; line-height: 45px; text-align: center; } }

.machine-portrait { display: none; }
@media screen and (max-width: 767px) and (orientation: portrait) { .machine-portrait { display: block; position: absolute; top: 0px; bottom: 0px; left: 0px; right: 0px; background: #ffb901; }
  .machine-portrait:before { content: ''; display: block; position: absolute; top: 0px; bottom: 0px; left: 50%; right: 0px; background: #fec52e; }
  .machine-portrait .rotate-note { width: 297px; position: absolute; top: 60px; bottom: 0px; left: 50%; transform: translateX(-50%); background: url(../img/portrait-top.png) no-repeat center top; }
  .machine-portrait .rotate-note .lights { position: absolute; top: 150px; bottom: 0px; left: 0px; right: 0px; background: url(../img/portrait-bg.png) repeat-y top center; }
  .machine-portrait .rotate-note .robbie { position: absolute; height: 265px; bottom: 0px; left: 0px; right: 0px; background: url(../img/portrait-bottom.png) no-repeat center bottom; } }

.app-bar { position: fixed; bottom: 0px; left: 0px; right: 0px; height: 90px; line-height: 100px; text-transform: uppercase; color: #1e1b1d; font-size: 24px; }
.app-bar .bar { background: rgba(255, 255, 255, 0.95) url(../img/app-icon.png) no-repeat center left 10px; padding-left: 100px; transition: all 300ms ease-out; }
.app-bar .bar p a { color: #d82a2f; }
.app-bar a.bar-trigger { display: block; position: absolute; top: 0px; right: 30px; color: #1e1b1d; }
.app-bar a.bar-trigger:hover { color: #ffc52e; }
.app-bar a.bar-trigger .show { display: none; color: #fff; }
.app-bar a.bar-trigger .show:hover { color: #ffc52e; }
.app-bar.hide .bar { transform: translateY(90px); pointer-events: none; }
.app-bar.hide a.bar-trigger .show { display: block; }
.app-bar.hide a.bar-trigger .hide { display: none; }
@media screen and (max-width: 767px) and (orientation: landscape) { .app-bar { line-height: 25px; display: none; }
  .app-bar .bar { padding: 20px 90px 20px 100px; height: 90px; }
  .app-bar a.bar-trigger { top: 0px; bottom: 0px; right: 0px; width: 40px; transition: none; }
  .app-bar a.bar-trigger > * { display: block; background: #2b2b2b; text-indent: -2000px; overflow: hidden; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
  .app-bar a.bar-trigger .hide { width: 12px; height: 2px; }
  .app-bar a.bar-trigger .show { height: 12px; width: 2px; }
  .app-bar.hide { height: 0px; }
  .app-bar.hide a.bar-trigger { top: -90px; }
  .app-bar.hide a.bar-trigger .show { background: #fff; }
  .app-bar.hide a.bar-trigger .hide { display: block; background: #fff; } }
@media screen and (max-width: 767px) and (orientation: portrait) { .app-bar { height: auto; line-height: 18px; font-size: 18px; }
  .app-bar .bar { padding: 15px 50px 10px 10px; height: auto; background-image: none; }
  .app-bar a.bar-trigger { top: auto; bottom: 10px; right: 10px; }
  .app-bar a.bar-trigger .show { color: #fff; }
  .app-bar a.bar-trigger .show:hover { color: #fff; } }

/* / EOF -- ------------------------------------------------------------------------- */


