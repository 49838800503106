$color_black                      : #000;
$color_white                      : #fff;

$color_dark                       : #2c2c2c;
$color_brand					  : #ffc52e;

$color_primary					  : #2c2c2c;

$color_red                        : #d82a2f;
$color_light                      : #fafafa;
$color_dimmed                     : #aaa;

$normalized_font_family           : 'ITCFranklinGothicStd', sans-serif;

$font_size_base                   : 2.9rem;
$line_height_base                 : 46/29;

$background                       : $color_dark;
$color                            : $color_primary;
$link_color                       : $color_white;
$link_color_hover                 : $color_brand;
$selection_bg                     : $color_brand;
$selection_text                   : $color_white;
$color_error                      : darken(red, 10%);

$transition_default               : all 300ms ease;
$transition_slow                  : all 800ms ease;
$transition_fast                  : all 100ms ease;


// z-index
$z_index_over_all                 : 10000;
$z_index_modal                    : 9000;
$z_index_overlay                  : 8000;
$z_index_dropdown                 : 7000;
$z_index_header                   : 6000;
$z_index_footer                   : 5000;
$z_index_over_content             : 1000;
$z_index_over_base                : 10;
$z_index_content                  : 5;
$z_index_base                     : 1;
$z_index_under_base               : -10;

// RWD

$bp-mobile-narrow                 : ( max:  413px );
$bp-mobile                        : ( max:  519px );
$bp-phablet                       : ( max:  767px );
$bp-portrait                      : ( max: 1023px );
$bp-min-portrait                  : ( min:  768px );
$bp-tablet                        : ( max: 1199px );
$bp-desktop                       : ( max: 1559px );
$bp-min-desktop                   : ( min: 1200px );
$bp-hd                            : ( min: map-get($bp-desktop, max) + 1 );

$bp-mobile                        : $bp-mobile;
$bp-phablet-only                  : ( min: map-get($bp-mobile, max) + 1, max: map-get($bp-phablet, max) );
$bp-portrait-only                 : ( min: map-get($bp-phablet, max) + 1, max: map-get($bp-portrait, max) );
$bp-tablet-only                   : ( min: map-get($bp-portrait, max) + 1, max: map-get($bp-tablet, max) );
$bp-desktop-only                  : ( min: map-get($bp-tablet, max) + 1, max: map-get($bp-desktop, max) );
$bp-hd-only                       : $bp-hd;
