// @font-face {
//     font-family: 'FONT_NAME';
//     src: url('../fonts/FILE.woff2') format('woff2'),
//          url('../fonts/FILE.woff') format('woff'),
//          url('../fonts/FILE.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

@font-face {
	font-family: 'ITCFranklinGothicStd';
	src: url('../fonts/ITCFranklinGothicStd-DmXCp.otf') format('truetype');
	font-weight: normal;
	font-style: normal;
}