body { -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: 100%; overflow-x: hidden; background: $background; }
*, *:before, *:after { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; }
input, button, textarea, select { -webkit-appearance: none; -webkit-font-smoothing: antialiased; }
* { outline: 0 !important; }
img { height: auto; image-rendering: -moz-crisp-edges; image-rendering: -o-crisp-edges; image-rendering: -webkit-optimize-contrast; image-rendering: crisp-edges; }


.clearfix { zoom: 1;
	&:before, &:after { content: "\0020"; display: block; height: 0; overflow: hidden; }
	&:after { clear: both; }
}

a, button { touch-action: none; }
button, input { -webkit-appearance: none; border-radius: 0; }

* { -webkit-tap-highlight-color: transparent !important; }

.fb-xfbml-parse-ignore { visibility: hidden; }
.alignleft { float: left; margin: 0 3rem 3rem 0; }

@function em($target, $context: $font_size_base) {
	@return ($target / $context) * 1em;
}

@mixin triangle($dir, $size, $color) {
    border: $size transparent solid;

    @if ($dir == "top" or $dir == "up") {
        border-bottom-color: $color;
    }
    @else if ($dir == "right" or $dir == "after") {
        border-left-color: $color;
    }
    @else if ($dir == "bottom" or $dir == "down") {
        border-top-color: $color;
    }
    @else if ($dir == "left" or $dir == "before") {
        border-right-color: $color;
    }
}

@mixin bp($map) {
  $query: "";
  @if map-has-key($map, min) { $query: append($query, "(min-width: #{map-get($map, min)})") }
  @if map-has-key($map, min) and map-has-key($map, max) { $query: append($query, "and") }
  @if map-has-key($map, max) { $query: append($query, "(max-width: #{map-get($map, max)})") }
  @if (map-has-key($map, min) or map-has-key($map, max)) and (map-has-key($map, minHeight) or map-has-key($map, maxHeight)) { $query: append($query, "and") }
  @if map-has-key($map, minHeight) { $query: append($query, "(min-height: #{map-get($map, minHeight)})") }
  @if map-has-key($map, minHeight) and map-has-key($map, maxHeight) { $query: append($query, "and") }
  @if map-has-key($map, maxHeight) { $query: append($query, "(max-height: #{map-get($map, maxHeight)})") }
  @media screen and #{$query} { @content; }
}

@mixin inview() {
	opacity: 0;
	&.inview-visible,
	.inview-visible & { opacity: 1; animation: appear-up 800ms $easeOutQuint forwards; }
}
