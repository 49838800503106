.bg-animation {

	$_colors :
		(theme: "default", c1: #363636, c2: #2C2C2C),
		(theme: "black", c1: #2C2C2C, c2: #111),
		(theme: "red", c1: darken(#de4a4e, 5%), c2: darken(#d82a2f, 5%)),
		(theme: "pink", c1: #f698a9, c2: #e98793),
		(theme: "green", c1: #099c3f, c2: #078937),
		(theme: "purple", c1: #6c549e, c2: #642681),
		(theme: "lightblue", c1: #d2eef8, c2: #bbdeea),
		(theme: "blue", c1: #0e82ca, c2: #0273ba);


	position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: $z_index_base; overflow: hidden;
	svg { display: block; width: auto; height: 300%; position: absolute; top: -60%; left: 50%; transform: translateX(-50%);
		@media screen and (min-width: 768px) {
			.bg-color-1 { animation: alt-color-1 2000ms ease-out; }
			.bg-color-2 { animation: alt-color-2 2000ms ease-out; }
			.bg-color-3 { animation: alt-color-3 2000ms ease-out; }
		}

		@keyframes alt-color-1 {
			0%    { fill: #363636; }
			33%   { fill: #2C2C2C; }
			100%  { fill: #363636; }
		}

		@keyframes alt-color-2 {
			0%    { fill: mix(#363636, #2C2C2C); }
			33%   { fill: #363636; }
			100%   { fill: #2C2C2C; }
		}

		@keyframes alt-color-3 {
			0%    { fill: #2C2C2C; }
			33%   { fill: mix(#363636, #2C2C2C); }
			100%  { fill: #363636; }
		}
	}

	@each $_color in $_colors {
		@media screen and (min-width: 768px) {
			&[data-animation="#{map-get($_color, theme)}"] { background-color: map-get($_color, c1);
				svg {
					.bg-color-1 { animation: alt-color-1--#{map-get($_color, theme)} 1000ms infinite linear; }
					.bg-color-2 { animation: alt-color-2--#{map-get($_color, theme)} 1000ms infinite linear; }
					.bg-color-3 { animation: alt-color-3--#{map-get($_color, theme)} 1000ms infinite linear; }
				}
			}
		}

		@keyframes alt-color-1--#{map-get($_color, theme)} {
			0%    { fill: map-get($_color, c1); }
			33%   { fill: map-get($_color, c2); }
			66%   { fill: mix(map-get($_color, c1), map-get($_color, c2)); }
			100%  { fill: map-get($_color, c1); }
		}

		@keyframes alt-color-2--#{map-get($_color, theme)} {
			0%    { fill: mix(map-get($_color, c1), map-get($_color, c2)); }
			33%   { fill: map-get($_color, c1); }
			66%   { fill: map-get($_color, c2); }
			100%  { fill: mix(map-get($_color, c1), map-get($_color, c2)); }
		}

		@keyframes alt-color-3--#{map-get($_color, theme)} {
			0%    { fill: map-get($_color, c2); }
			33%   { fill: mix(map-get($_color, c1), map-get($_color, c2)); }
			66%   { fill: map-get($_color, c1); }
			100%  { fill: map-get($_color, c2); }
		}
	}

}
