.button { display: block; width: 22.73%; height: 13.79%; padding: 2.8% 0 1.88%; color: $color_white; font-size: 24px; line-height: 24px; text-align: center; border-radius: 5px; text-transform: uppercase; white-space: nowrap;

	@media screen and (max-width: 767px) and (orientation: landscape){
		font-size: 18px; padding: 0; line-height: 36px; height: 30px; width: 105px;
	}

	@media screen and (max-width: 1023px){
		font-size: 18px; padding: 0; line-height: 36px; height: 30px; width: 105px;
	}

	&--red { background: $color_red;
		.no-touch &:hover { background: $color_brand; color: #fff; }
	}
	&--border { border: 2px solid $color_white; padding-top: 2.6%;
		.no-touch &:hover { background: $color_white; color: #363636; }
		@media screen and (max-width: 767px) and (orientation: landscape){
			border-width: 1px; line-height: 34px;
		}

		@media screen and (max-width: 1023px){
			border-width: 1px; line-height: 34px; padding-top: 0%;
		}
	}
	&--spotify { background: #2c2c2c url(../img/icon-spotify.png) no-repeat center left 22px; padding-left: 70px; padding-right: 30px; width: auto;
		@media screen and (max-width: 1023px) { background-position: center left 10px; background-size: 18px; padding-left: 40px; padding-right: 15px; }
	}
}

.spin-again { display: inline-block; position: absolute; top: 10px; left: 30px; color: #fff; font-size: 24px; text-transform: uppercase; z-index: 3;
	&:before { content: ''; display: block; width: 0; height: 0; border-style: solid; border-width: 4px 6px 4px 0; border-color: transparent #fff transparent transparent; position: absolute; left: -16px; top: 10px; transition: all 250ms ease-out;
		@media screen and (max-width: 1023px) { top: 7px; }
	}
	&:hover { color: #ffc52e;
		&:before { border-color: transparent #ffc52e transparent transparent; }
	}

	@media screen and (max-width: 1023px) { font-size: 18px; }
}
