html { min-height: 100%; position: relative; overflow-x: hidden; }
html,body { overflow: hidden; }

.sme_cookie .sme_cookie_container { font-size: 18px;

	@media screen and (max-width: 767px){ font-size: 14px; }

}

//.overall { margin: 0 auto; padding: 0 250px; }
.wrapper { width: 100%; max-width: 1185px; margin: 0 auto; }

.overall { position: fixed; top: 0; left: 0; right: 0; background: $color_dark; }

.official-website { position: absolute; top: 15px; right: 20px; color: #fff; font-size: 19px; z-index: 10;
	@media screen and (max-width: 767px) and (orientation: landscape) { display: none; }
	@media screen and (max-width: 767px) and (orientation: portrait) { top: 0px; left: 0px; right: 0px; position: fixed; background: #000; height: 40px; line-height: 45px; text-align: center; }
}

.machine-portrait { display: none;

	@media screen and (max-width: 767px) and (orientation: portrait){
		display: block; position: absolute; top: 0px; bottom: 0px; left: 0px; right: 0px; background: #ffb901;
		&:before { content: ''; display: block; position: absolute; top: 0px; bottom: 0px; left: 50%; right: 0px; background: #fec52e; }

		.rotate-note { width: 297px; position: absolute; top: 60px; bottom: 0px; left: 50%; transform: translateX(-50%); background: url(../img/portrait-top.png) no-repeat center top;
			.lights { position: absolute; top: 150px; bottom: 0px; left: 0px; right: 0px; background: url(../img/portrait-bg.png) repeat-y top center; }
			.robbie { position: absolute; height: 265px; bottom: 0px; left: 0px; right: 0px; background: url(../img/portrait-bottom.png) no-repeat center bottom; }
		}

	}

}

.app-bar { position: fixed; bottom: 0px; left: 0px; right: 0px; height: 90px; line-height: 100px; text-transform: uppercase; color: #1e1b1d; font-size: 24px;
	.bar { background: rgba(255, 255, 255, 0.95) url(../img/app-icon.png) no-repeat center left 10px; padding-left: 100px; transition: all 300ms ease-out;
		p a { color: #d82a2f; }
	}
	a.bar-trigger { display: block; position: absolute; top: 0px; right: 30px; color: #1e1b1d;
		&:hover { color: $link_color_hover; }
		.show { display: none; color: #fff;
			&:hover { color: $link_color_hover; }
		}
	}

	&.hide {
		.bar { transform: translateY(90px); pointer-events: none; }
		a.bar-trigger .show { display: block; }
		a.bar-trigger .hide { display: none; }
	}

	@media screen and (max-width: 767px) and (orientation: landscape) { line-height: 25px; display: none;
		.bar { padding: 20px 90px 20px 100px; height: 90px; }
		a.bar-trigger { top: 0px; bottom: 0px; right: 0px; width: 40px; transition: none;
			& > * { display: block; background: #2b2b2b; text-indent: -2000px; overflow: hidden; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
			.hide { width: 12px; height: 2px;  }
			.show { height: 12px; width: 2px;  }
		}

		&.hide { height: 0px;
			a.bar-trigger { top: -90px; }
			a.bar-trigger .show { background: #fff; }
			a.bar-trigger .hide { display: block; background: #fff; }
		}
	}

	@media screen and (max-width: 767px) and (orientation: portrait) { height: auto; line-height: 18px; font-size: 18px;
		.bar { padding: 15px 50px 10px 10px; height: auto; background-image: none; }
		a.bar-trigger { top: auto; bottom: 10px; right: 10px;
			.show { color: #fff;
				&:hover { color: #fff; }
			}
		}
	}
}
