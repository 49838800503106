.slot-machine { max-width: 1185px; height: 100vh; position: relative; z-index: $z_index_over_base; margin: 0 auto;

	.machine { position: absolute; bottom: 0px; width: 100%;

		@media screen and (max-width: 767px) and (orientation: landscape){ left: 10px; right: 10px; bottom: 10px; width: auto; }

		svg { display: block; padding-top: 5.47%; max-height:100%; width: auto;

			&.machine-mobile { display: none; padding: 0; }

			@media screen and (max-width: 767px) and (orientation: landscape){

				&.machine-desktop { display: none; }
				&.machine-mobile { display: block; }

			}

			@media screen and (max-width: 767px) and (orientation: portrait){

				&.machine-desktop { display: none; }
				&.machine-mobile { display: none; }

			}

			#Button-left-copy-2 { cursor: pointer; }
			#Lights circle,
			#LightsMobile ellipse {opacity:0.5; }
			#Lights[data-animation="animationBlinking"] circle,
			#LightsMobile[data-animation="animationBlinking"] ellipse { animation: animationBlinking 320ms steps(2, start) infinite; }

			#mobile-button-active { pointer-events: none; visibility: hidden; }

			#Lights[data-animation="animationSnake"] {

				$count    : 54; // how many dots?
				$duration : 8; // for how long (in percent) the animation should stay at opacity: 1
				$range    : 50 - $duration - 1;

				@for $i from 1 through $count {
				  & circle:nth-child(#{$i}) { animation: snake-#{$i} 2.5s infinite linear; }
				  & ellipse:nth-child(#{$i}) { animation: snake-#{$i} 2.5s infinite linear; }
				  @keyframes snake-#{$i} {
				    0% { opacity: 0.5; }
				    #{$i*($range/$count)}% { opacity: 0.5; }
				    #{$i*($range/$count) + 1}% { opacity: 1; }
				    #{$i*($range/$count) + $duration}% { opacity: 1; }
				    #{$i*($range/$count) + $duration + 1}% { opacity: 0.5; }
				    61% { opacity: 1; }
				    67% { opacity: 1; }
				    70% { opacity: 0.5; }
				    75% { opacity: 1; }
				    80% { opacity: 0.5; }
				    85% { opacity: 1; }
				    90% { opacity: 0.5; }
				    95% { opacity: 1; }
				    100% { opacity: 0.5; }
				  }
				}
			}

			#LightsMobile[data-animation="animationSnake"] {

				$count    : 56; // how many dots?
				$duration : 8; // for how long (in percent) the animation should stay at opacity: 1
				$range    : 50 - $duration - 1;

				@for $i from 1 through $count {
				  & circle:nth-child(#{$i}) { animation: snake-#{$i} 2.5s infinite linear; }
				  & ellipse:nth-child(#{$i}) { animation: snake-#{$i} 2.5s infinite linear; }
				  @keyframes snake-#{$i} {
				    0% { opacity: 0.5; }
				    #{$i*($range/$count)}% { opacity: 0.5; }
				    #{$i*($range/$count) + 1}% { opacity: 1; }
				    #{$i*($range/$count) + $duration}% { opacity: 1; }
				    #{$i*($range/$count) + $duration + 1}% { opacity: 0.5; }
				    61% { opacity: 1; }
				    67% { opacity: 1; }
				    70% { opacity: 0.5; }
				    75% { opacity: 1; }
				    80% { opacity: 0.5; }
				    85% { opacity: 1; }
				    90% { opacity: 0.5; }
				    95% { opacity: 1; }
				    100% { opacity: 0.5; }
				  }
				}
			}

			.st10 { fill: #f2f2f2; transition: fill 300ms ease; }
			.st13 { fill-opacity: 1; }
			#Button-left-copy-2 .st7 { fill-opacity: 1; }

			#Group-22-copy .st10 { animation-delay: 50ms; }
			#Group-22-copy-2 .st10 { animation-delay: 100ms; }

			#Lights[data-animation="animationAround"] {

				$count    : 54; // how many dots?
				$duration : 8; // for how long (in percent) the animation should stay at opacity: 1
				$range    : 60 - $duration - 1;

				@for $i from 1 through $count {
				  & circle:nth-child(#{$i}) { animation: around-#{$i} 3s infinite linear ; }
				  & ellipse:nth-child(#{$i}) { animation: around-#{$i} 3s infinite linear ; }
				  @keyframes around-#{$i} {
				    0% { opacity: 0.5; }
				    #{$i*($range/$count)}% { opacity: 0.5; }
				    #{$i*($range/$count) + 1}% { opacity: 1; }
				    61% { opacity: 1; }
				    67% { opacity: 1; }
				    70% { opacity: 0.5; }
				    75% { opacity: 1; }
				    80% { opacity: 0.5; }
				    85% { opacity: 1; }
				    90% { opacity: 0.5; }
				    95% { opacity: 1; }
				    100% { opacity: 0.5; }
				  }
				}
			}

			#LightsMobile[data-animation="animationAround"] {

				$count    : 56; // how many dots?
				$duration : 8; // for how long (in percent) the animation should stay at opacity: 1
				$range    : 60 - $duration - 1;

				@for $i from 1 through $count {
				  & circle:nth-child(#{$i}) { animation: around-#{$i} 3s infinite linear ; }
				  & ellipse:nth-child(#{$i}) { animation: around-#{$i} 3s infinite linear ; }
				  @keyframes around-#{$i} {
				    0% { opacity: 0.5; }
				    #{$i*($range/$count)}% { opacity: 0.5; }
				    #{$i*($range/$count) + 1}% { opacity: 1; }
				    61% { opacity: 1; }
				    67% { opacity: 1; }
				    70% { opacity: 0.5; }
				    75% { opacity: 1; }
				    80% { opacity: 0.5; }
				    85% { opacity: 1; }
				    90% { opacity: 0.5; }
				    95% { opacity: 1; }
				    100% { opacity: 0.5; }
				  }
				}
			}
		}
	}

	&.is-horizontal {
		.machine { width: auto; height: 100%;
			svg { width: auto; height: 100%; margin: 0 auto; max-width: 1185px; }

			@media screen and (max-width: 767px) and (orientation: landscape){ height: calc(100% - 20px); }
		}
	}

	.machine-screen { position: absolute; width: 64.98%; height: 43.303%; top: 28.5%; left: 50%; transform: translateX(-50%); border-radius: 5px; overflow: hidden;
		&:before { content: ''; display: block; position: absolute; top: 0px; left: 5px; right: 0px; height: 5px; background-color: rgba(0, 0, 0, 0.1); z-index: 10;
			@media screen and (max-width: 767px) and (orientation: landscape){ height: 3px; left: 3px; }
		}
		&:after { content: ''; display: block; position: absolute; top: 0px; bottom: 0px; left: 0px; width: 5px; background-color: rgba(0, 0, 0, 0.1); z-index: 10;
			@media screen and (max-width: 767px) and (orientation: landscape){ width: 3px; }
		}

		@media screen and (max-width: 767px) and (orientation: landscape){ width: 86.96%; height: 60%; top: 9.8%; }

		.screen { width: 100%; height: 100%; background-repeat: no-repeat; background-position: center; display: none;

			&-start { display: block; background: #fff;
				.claim { width: 85.06%; height: 59.31%; position: absolute; top: 8%; left: 50%; transform: translateX(-50%) scale(0); background: url(../img/screen-start-claim.png) no-repeat center; background-size: contain; }
				.emoji { width: 48.83%; height: 67.82%; position: absolute; bottom: -34%; left: 50%; transform: translateX(-50%) scale(0); background: url(../img/screen-start-emoji.png) no-repeat center; z-index: 1; margin-left: 2%; background-size: contain; }
				.button { position: absolute; bottom: 0px; left: 50%; transform: translateX(-50%); opacity: 0; z-index: 2; }

				&.active {
					.claim { transform: translateX(-50%) scale(1); transition: transform 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
					.emoji { transform: translateX(-50%) scale(1); bottom: 0px; transition: all 600ms 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);  }
					.button { opacity: 1; bottom: 20px; transition: opacity 300ms 800ms ease-out, bottom 300ms 800ms ease-out, background 250ms ease-out; }
				}
			}

			&-machine { background: #f2f2f2; }

			&-gif { background: #333; background-size: cover; background-repeat: no-repeat; background-position: 50%; }

			&-unlucky { background: #363636;

				.matches { position: absolute; top: 0px; left: 50%; transform: translateX(-50%); font-size: 24px; color: #fff; text-transform: uppercase; opacity: 1;
					@media screen and (max-width: 767px) and (orientation: landscape){ font-size: 18px; }
					@media screen and (max-width: 1023px) { font-size: 18px; }
				}
				.claim { width: 81.69%; height: 31.95%; position: absolute; top: 34.48%; left: 50%; transform: translateX(-50%) scale(0); background: url(../img/screen-unlucky-claim.png) no-repeat center; background-size: contain; }
				.emoji { width: 15.06%; height: 29.43%; position: absolute; top: 20.69%; left: 50%; transform: translateX(-50%) scale(0); background: url(../img/screen-unlucky-emoji.png) no-repeat center; z-index: 1; margin-left: 1.5%; background-size: contain; }
				.button { position: absolute; bottom: 0px; left: 50%; transform: translateX(-50%); opacity: 0; z-index: 2; }

				&.active {
					.matches { top: 3.45%; opacity: 1; transition: all 300ms ease-out; }
					.claim { transform: translateX(-50%) scale(1); transition: transform 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
					.emoji { transform: translateX(-50%) scale(1); transition: transform 600ms 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);  }
					.button { opacity: 1; bottom: 20px; transition: opacity 300ms 400ms ease-out, bottom 300ms 400ms ease-out, background 250ms ease-out, color 250ms ease-out; }
				}
			}

			&-lucky {

				.claim { position: absolute; left: 50%; transform: translateX(-50%) scale(0); background-repeat: no-repeat; background-position: center; background-size: contain; }
				.image { position: absolute; top: 0px; bottom: 0px; left: 0px; right: 0px; background-size: contain; background-repeat: no-repeat; background-position: center; }
				.button { position: absolute; bottom: 0px; left: 50%; transform: translateX(-50%); opacity: 0; z-index: 2; }


				&[data-emoji="Thinking.jpg"] { background-color: #bcdfeb; background-image: linear-gradient( 90deg, rgb(210,239,248) 0%, rgb(186,222,234) 100%);
					.claim { width: 95.19%; height: 42.06%; top: 29%; background-image: url(../img/screen-lucky/thinking.png); }
					.image { background-image: url(../img/screen-lucky/img-thinking.png); }
				}

				&[data-emoji="HeartEyes.jpg"] { background-color: #e12026; background-image: linear-gradient( 90deg, rgb(232,64,64) 0%, rgb(226,34,40) 77%, rgb(219,4,16) 100%);
					.claim { width: 93.51%; height: 52.64%; top: 18.39%; background-image: url(../img/screen-lucky/love.png); }
					.image { background-image: url(../img/screen-lucky/img-love.png); }
				}

				&[data-emoji="CryCry.jpg"] { background-color: #0475bd; background-image: linear-gradient( 90deg, rgb(14,130,202) 0%, rgb(2,115,186) 100%);
					.claim { width: 94.68%; height: 49.43%; top: 26%; background-image: url(../img/screen-lucky/crying.png); }
					.image { background-image: url(../img/screen-lucky/img-crying.png); }
				}

				&[data-emoji="BigSmile.jpg"] { background-color: #2d2d2d; background-image: linear-gradient( 90deg, rgb(54,54,54) 0%, rgb(44,44,44) 100%);
					.claim { width: 94.94%; height: 42.99%; top: 30%; background-image: url(../img/screen-lucky/upbeat.png); }
					.image { background-image: url(../img/screen-lucky/img-upbeat.png); }
				}

				&[data-emoji="Candy.jpg"] { background-color: #652b84; background-image: linear-gradient( 90deg, rgb(108,86,159) 0%, rgb(100,37,128) 100%);
					.claim { width: 94.81%; height: 38.62%; top: 31.03%; background-image: url(../img/screen-lucky/dance.png); }
					.image { background-image: url(../img/screen-lucky/img-dance.png); }
				}

				&[data-emoji="Captain.jpg"] { background-color: #652a83; background-image: linear-gradient( 90deg, rgb(246,152,169) 0%, rgb(233,135,147) 100%);
					.claim { width: 94.55%; height: 55.63%; top: 23%; background-image: url(../img/screen-lucky/swing.png); }
					.image { background-image: url(../img/screen-lucky/img-swing.png); }
				}




				&[data-emoji="Football5.jpg"] { background-color: #e22228; background-image: linear-gradient( 90deg, rgb(232,64,64) 0%, rgb(226,34,40) 77%, rgb(219,4,16) 100%);
					.claim { width: 94.81%; height: 29.89%; top: 36.78%; background-image: url(../img/screen-lucky/workout.png); }
					.image { background-image: url(../img/screen-lucky/img-workout.png); }
				}

				&[data-emoji="Knebworth.jpg"] { background-color: #2d2d2d; background-image: linear-gradient( 90deg, rgb(54,54,54) 0%, rgb(44,44,44) 100%);
					.claim { width: 78.83%; height: 57.47%; top: 20.7%; background-image: url(../img/screen-lucky/live.png); }
					.image { background-image: url(../img/screen-lucky/img-live.png); }
				}

				&[data-emoji="Sleeping.jpg"] { background-color: #2e2e2; background-image: linear-gradient( 90deg, rgb(9,156,63) 0%, rgb(7,137,55) 100%);
					.claim { width: 94.81%; height: 65.06%; top: 17%; background-image: url(../img/screen-lucky/chill.png); }
					.image { background-image: url(../img/screen-lucky/img-chill.png); }
				}


				&[data-emoji="Defiant.jpg"] { background-color: #2e2e2e; background-image: linear-gradient( 90deg, rgb(54,54,54) 0%, rgb(44,44,44) 100%);
					.claim { width: 49.35%; height: 47.13%; top: 19.54%; background-image: url(../img/screen-lucky/defiant.png); }
				}

				&[data-emoji="RocknRoll.jpg"] { background-color: #323232; background-image: linear-gradient( 90deg, rgb(54,54,54) 0%, rgb(44,44,44) 100%);
					.claim { width: 41.81%; height: 59.55%; top: 14.77%; background-image: url(../img/screen-lucky/rocknroll.png); }
				}



				&.active {
					.claim { transform: translateX(-50%) scale(1); transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
					.button { opacity: 1; bottom: 20px; transition: opacity 300ms 800ms ease-out, bottom 300ms 800ms ease-out, background 250ms ease-out, color 250ms ease-out; }
				}

			}

			&-spotify { background: #fff; padding: 50px 20px 0 20px;

				.spin-again { color: #2c2c2c;
					&:before { border-color: transparent #2c2c2c transparent transparent; }
					&:hover { color: #ffc52e;
						&:before { border-color: transparent #ffc52e transparent transparent; }
					}
				}
				.iframe-container { position: absolute; top: 50px; bottom: 95px; left: 20px; right: 20px; overflow: hidden;
					iframe { width: 100% !important; min-height: 280px !important;
						@media screen and (max-width: 767px) { min-height: 80px !important; height: 80px !important; }
					}

					@media screen and (max-width: 767px) { height: 80px; top: 40px; bottom: auto; }
				}

				.socials { position: absolute; bottom: 20px; left: 20px; right: 20px;
					@media screen and (max-width: 767px) { bottom: 10px; 
						.vsmall & .twitter { display: none; }
					}

					@media screen and (max-width: 500px), screen and (max-width: 767px) and (max-height: 350px) { display: none; }
					& > * { height: 56px; line-height: 56px; }
					.fb { float: left; margin-right: 10px; }
					.twitter { float: left; }
					.spotify {  float: right; }
				}

			}
		}




		.slots { display: flex; margin: 0 -5px;
			@media screen and (max-width: 767px) and (orientation: landscape){ margin: 0 5px; }
			.slot { width: 33.333%; overflow: hidden; position: relative;
				&:before { content: ''; display: block; padding-top: 200%; }
			}
			.slot-animation-wrapper { width: 100%; position: absolute; top: -40%; left: 0; will-change: transform; }
			.slot-item { padding: 5px;
				.slot-item-inner { overflow: hidden; background: $color_white;position: relative;
					&:after { content: ''; display: block; position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px; background: url(../img/emoji-shadow.png) no-repeat top center; background-size: contain; }
					&:before { display: block; content: ''; padding-top: 110%;
						@media screen and (max-width: 767px) and (orientation: landscape){ padding-top: 100%; }
					}
				}

				img { display: block; height: auto; width: 100%; max-width: none; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
			}
		}

	}

	&.ready-to-spin {
		.st10 { animation: button-blink 300ms linear infinite alternate;
		}
		.machine svg .st13 { fill-opacity: 0.15; }
		.machine svg #Button-left-copy-2 .st7 { fill-opacity: 0.25; }

		#mobile-button {
			rect[fill="#d52a2e"] { animation: button-blink 300ms linear infinite alternate; }
		}
		#mobile-button:active + #mobile-button-active { visibility: visible; }
		#mobile-button-active.touch-hover { visibility: visible; }

		@keyframes button-blink {
			0%  { fill: #d7202f; }
			100%  { fill: #f00; }
		}
	}

	&:not(.ready-to-spin) {
		#mobile-button {
			rect[fill="#d52a2e"] { fill: #f2f2f2; }
			path[fill="#631316"] { fill: #777; }
			path[fill="#bc2026"] { fill: #dadada; }
			path[fill="#f04a4e"] { fill: #fff; }
		}
	}
}
